import React, { useState, useContext, useEffect } from 'react'
import { StateContext, TimeFormatSeconds } from '../../../state';
import http from '../../../../../utils/server'
import { Row, Col, Button, Spin, Modal, Input, Space, Checkbox, Divider, DatePicker } from 'antd'
import moment from 'moment';

import './device.less'
import { Mode } from '../../../../../App';
const { RangePicker } = DatePicker;
function formatDateTimes(inputTime) {
    var date = new Date(inputTime);
    var y = date.getFullYear();
    var m = date.getMonth() + 1;
    m = m < 10 ? ('0' + m) : m;
    var d = date.getDate();
    d = d < 10 ? ('0' + d) : d;
    var h = date.getHours();
    h = h < 10 ? ('0' + h) : h;
    var minute = date.getMinutes();
    var second = date.getSeconds();
    minute = minute < 10 ? ('0' + minute) : minute;
    second = second < 10 ? ('0' + second) : second;
    return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + second;
};

function DeviceRoomSet(props) {
    const { state, dispatch } = useContext(StateContext)

    const [device, setDevice] = useState(props.item)
    const [runTime, setRunTime] = useState({ validity: false, error: false, type: 1, value: 10 })
    const [load, setLoad] = useState(false)
    const SetDeviceOpenDoor = (t) => {
        if (runTime.error) {
            Modal.error({
                title: '参数错误',
                centered: true,
                content: '请检查所设置的参数',
            });
            return
        }
        setLoad(true)
        http.post((props.cloud ? "/v3" : "/v1") + "/setdeviceio", { device: device.Sn, status: 1, channel: 1, mode: 0, time: runTime.value }).then(data => {
            setLoad(false)
            if (data.code === 0) {
                setDevice({ ...device, OffLineAlarm: t })
            }
        })
    }
    const SetDeviceCloseDoor = (t) => {
        if (runTime.error) {
            Modal.error({
                title: '参数错误',
                centered: true,
                content: '请检查所设置的参数',
            });
            return
        }
        setLoad(true)
        http.post((props.cloud ? "/v3" : "/v1") + "/setdeviceio", { device: device.Sn, status: 1, channel: 2, mode: 0, time: runTime.value }).then(data => {
            setLoad(false)
            if (data.code === 0) {
                setDevice({ ...device, LinkAlarm: t })
            }
        })
    }
    const SetDeviceParams = (t) => {
        if (t.validity) {
            if (t.error) {
                Modal.error({
                    title: '参数错误',
                    centered: true,
                    content: '请检查所设置的参数',
                });
            } else {
                setLoad(true)
                http.post((props.cloud ? "/v3" : "/v1") + "/setdevice", { ...t, device: device }).then(data => {
                    setLoad(false)
                })
            }
        } else {
            Modal.error({
                title: '操作错误',
                centered: true,
                content: '设置之前必须先读取参数',
            });
        }
    }
    return (

        <Spin spinning={load}>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: '0px 20px' }}>
                <span>{device.Name}</span> <span>{' 主机编号：' + device.Sn}</span> <span>{' 测点ID: ' + device.Node}</span> <span>{Mode !== 'soc' ? null : ' 用户: ' + state.district.filter(item => item.id === device.District)[0].user_name}</span>
                <span>{' 区域: ' + state.district.filter(item => item.id === device.District)[0].name}</span>
            </div>
            <Row gutter={[10, 10]} style={{ position: 'relative' }}>
                <Divider orientation="left">基本设置</Divider>
                <Col span={6} style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                    <Input value={runTime.value} addonBefore={"动作时间："} addonAfter={"秒"} onChange={e => {
                        var reg = /^[0-9]*[1-9][0-9]*$/
                        if (reg.test(e.target.value)) {
                            setRunTime({ ...runTime, error: false, value: Number(e.target.value) })
                        } else {
                            setRunTime({ ...runTime, error: true, value: e.target.value })
                        }
                    }} />
                </Col>
                <Col span={9} style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                    <div>开启遮阳：<Button onClick={() => {
                        SetDeviceOpenDoor(device.Sensor.OffLineAlarm === 0 ? 1 : 0)
                    }} disabled={device.IOModel.Status[0] === 1 || (device.IOModel.Status[1] === 1 && device.IOModel.Status[0] === 1)}>
                        {device.IOModel.Status[0] === 0 ? "打开" : "已打开"}
                    </Button>
                    </div>
                </Col>
                <Col span={9} style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                    <div>关闭遮阳：<Button onClick={() => {
                        SetDeviceCloseDoor(device.Sensor.LinkAlarm === 0 ? 1 : 0)
                    }} disabled={device.IOModel.Status[1] === 1 || (device.IOModel.Status[1] === 1 && device.IOModel.Status[0] === 1)}>
                        {device.IOModel.Status[1] === 0 ? "打开" : "已打开"}
                    </Button>
                    </div>
                </Col>
                <Divider orientation="left">设备状态</Divider>
                {
                    (() => {
                        if (device.IOModel.Status[0] === 0 && device.IOModel.Status[1] === 0) {
                            return "设备待机中"
                        }else if (device.IOModel.Status[0] === 2 && device.IOModel.Status[1] === 0) {
                            return "正在开启遮阳"
                        } else if (device.IOModel.Status[1] === 2 && device.IOModel.Status[0] === 0) {
                            return "正在关闭遮阳"
                        } else if (device.IOModel.Status[0] === 1 && device.IOModel.Status[1] === 0) {
                            return "已经开启遮阳"
                        } else if (device.IOModel.Status[1] === 1 && device.IOModel.Status[0] === 0) {
                            return "已经关闭遮阳"
                        } else {
                            return "设备出现故障"
                        }
                    })()
                }
                <Divider orientation="left">开关状态</Divider>
                {
                    device.IOModel.Status.map((item, index) => (
                        <Col span={4}>IO{index + 1}:{item}</Col>
                    ))
                }

            </Row>
        </Spin>
    )
}

export default DeviceRoomSet