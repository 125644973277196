import React, { createContext, useContext, useEffect, useState, useReducer } from 'react'
import { Table, Button, Input, Select, Modal, Row, Col, } from 'antd'
import { StateContext } from '../../../state';
import http from '../../../../../utils/server'
import { ExclamationCircleOutlined } from '@ant-design/icons';

function SelectInput(props) {
    if(props.option){
        return (
            <div style={{ display: 'flex' }}>
                <div style={{ ...props.style, padding: '0 11px', paddingTop: 4, backgroundColor: '#fafafa', border: '1px solid #d9d9d9', borderRight: '0px', borderRadius: 2 }}>{props.addonBefore}</div>
                <Select disabled={props.disabled} value={props.defaultValue} style={{ ...props.style, flex: 1 }} onChange={(value) => { props.onChange && props.onChange(value) }}>
                    {props.option.map((item, index) => (
                        <Select.Option key={index} value={item[props.keyword]}>{item[props.text]}</Select.Option>
                    ))}
                </Select>
            </div>
        )
    }else{
        return null
    }
    
}

const AddressContext = createContext();

function AddressNew() {
    const { actAddress, dispatchAddress } = useContext(AddressContext)
    const { state } = useContext(StateContext)
    // const [centerP, setCenterP] = useState([]);
    // const [map, setMap] = useState({});
    useEffect(() => {
    //    var lng,lat;
    //     var map = new window.AMap.Map('container', {
    //         center:[117.000923,36.675807],
    //         zoom:22
    //     });
    //     var geolocation = new window.AMap.Geolocation({});
    //     geolocation.getCurrentPosition((status,result)=>{
    //         if (status === 'complete' && result.info === 'OK'){
    //             setCenterP([result.position.lng,result.position.lat])
    //             lng=result.position.lng; lat = result.position.lat;
    //         }
    //         console.log(result.position.lng);
            
    //     }); 
    //     // map.addControl(geolocation);
    //     setTimeout(() => {
    //         // map.setCenter([lng,lat]);
    //         var marker = new window.AMap.Marker({
    //             position:[lng,lat]
    //         })
    //         map.add(marker);
    //     }, 2000);


    }, [])
    return (
        <div >
            <SelectInput style={{ marginBottom: 10 }} addonBefore={<span style={{color:'red'}}>* <span style={{color:'#000'}}>地址类型:</span></span>} defaultValue={actAddress.type} option={state.addresstype.filter(x=>x.code!==403)} keyword={"code"} text='value'
                onChange={value => { dispatchAddress({ type: 'update', address: { ...actAddress, type: value,user:'' } }) }} />
            <SelectInput style={{ marginBottom: 10 }} addonBefore={<span style={{color:'red'}}>* <span style={{color:'#000'}}>所属单位:</span></span>} defaultValue={actAddress.user} option={state.transuser&&state.transuser.filter(x=>state.systemuser.list.some(us=>us.id===x.users) &&(actAddress.type===401?x.type===901:actAddress.type===402?x.type===902:actAddress.type===403?x.type===903:true))} keyword={"id"} text='name'
                onChange={value => { dispatchAddress({ type: 'update', address: { ...actAddress, user: value } }) }} />  
            <Input style={{ marginBottom: 10 }} addonBefore={<span style={{color:'red'}}>* <span style={{color:'#000'}}>地址:</span></span>} value={actAddress.address}
                onChange={e => { dispatchAddress({ type: 'update', address: { ...actAddress, address: (e.target.value) } }) }} 
                />
            {/* <div id="container" style={{height:200}}></div> */}
        </div>
    )
}

const PersonContext = createContext();

function PersonNew() {
    const { actPerson, dispatchPerson } = useContext(PersonContext)
    const { state } = useContext(StateContext)
    return (
        <div>
            <SelectInput style={{ marginBottom: 10 }} addonBefore={<span style={{color:'#000'}}>单位类型:</span>}   option={state.tusertype?state.tusertype:[]} keyword={"code"} text='value'
                defaultValue={actPerson.type} onChange={value => { dispatchPerson({ type: 'update', person: { ...actPerson, type: value ,user:''} }) }} />
            <SelectInput style={{ marginBottom: 10 }} addonBefore={<span style={{color:'red'}}>* <span style={{color:'#000'}}>所属单位:</span></span>}  option={state.transuser&&state.transuser.filter(x=>actPerson.type===0? x.users===state.main.id:x.users===state.main.id&&actPerson.type===x.type)} keyword={"id"} text='name'
                defaultValue={actPerson.user} onChange={value => { dispatchPerson({ type: 'update', person: { ...actPerson, user: value } }) }} />  
            <Input style={{ marginBottom: 10 }} addonBefore={<span style={{color:'red'}}>* <span style={{color:'#000'}}>联系人:</span></span>} value={actPerson.name}
                onChange={(e) => { dispatchPerson({ type: 'update', person: { ...actPerson, name: (e.target.value) } }) }} />
            <Input style={{ marginBottom: 10 }} addonBefore={<span style={{color:'red'}}>* <span style={{color:'#000'}}>联系方式:</span></span>} value={actPerson.phone}
                onChange={(e) => { dispatchPerson({ type: 'update', person: { ...actPerson, phone: (e.target.value*1) } }) }} />
        </div>
    )
}

function AddressManage() {
    const { state, dispatch } = useContext(StateContext)
    const [actAddress, dispatchAddress] = useReducer((state, action) => {
        if (action.type === 'update') {
            return action.address
        } else if (action.type === 'clear') {
            return {
                type: '',
                address: '',
                user:''
            }
        }
        return state
    }, []);
    const [showNewModel, setShowNewModel] = useState(false)
    const [showFixModel, setShowFixModel] = useState(false)

    const [actPerson, dispatchPerson] = useReducer((state, action) => {
        if (action.type === 'update') {
            return action.person
        } else if (action.type === 'clear') {
            return {
                name: '',
                phone: '',
                user:'',
                type:''
            }
        }
        return state
    }, []);
    const [showNewPModel, setShowNewPModel] = useState(false)
    const [showFixPModel, setShowFixPModel] = useState(false)
    useEffect(() => {
        // var script = document.createElement('script');
        // script.type = 'text/javascript';
        // script.async = true;
        // script.src = 'https://webapi.amap.com/maps?v=1.4.15&key=56e05414695293038cbd67fbc00b3218&plugin=AMap.Geocoder,AMap.Geolocation';
        // document.head.appendChild(script);    
        http.get("/v1/transaddress", {}).then(data => {
            if (data.code === 0) {
                dispatch({ type: 'address', address: data.data })
            }else{
                dispatch({ type: 'address', address: [] })
            }
        })
        http.get("/v1/addresstype", {}).then(data => {
            if (data.code === 0) {
                dispatch({ type: 'addresstype', addresstype: data.data })
            }else{
                dispatch({ type: 'addresstype', addresstype: [] })
            }
        })
        http.get("/v1/transaddressuser", {}).then(data => {
            if (data.code === 0) {
                dispatch({ type: 'addressuser', addressuser: data.data })
            }else{
                dispatch({ type: 'addressuser', addressuser: [] })
            }
        })
        http.get("/v1/transuser", {}).then(data => {
            if (data.code === 0) {
                dispatch({ type: 'transuser', transuser: data.data })
            }else{
                dispatch({ type: 'transuser', transuser: [] })
            }
        });
        http.get("/v1/tuserstype", {}).then(data => {
            if (data.code === 0) {
                dispatch({ type: 'tusertype', tusertype: data.data })
            }else{
                dispatch({ type: 'tusertype', tusertype: [] })
            }
        });
    }, [])
    const columns = [
        {
            title: '类型',
            dataIndex: 'type',
            align: 'left',
            render: (text, record) => (
                <div>
                    {
                        state.addresstype.filter(x => { return x.code === record.type }).length > 0 ? state.addresstype.filter(x => { return x.code === record.type })[0].value : '无'
                    }
                </div>
            )
        },
        {
            title: '地址',
            dataIndex: 'address',
            align: 'center',
        },
        {
            title: '所属单位',
            dataIndex: 'user',
            align: 'center',
            render: (text, record) => (
                <div>
                    {
                        state.transuser&&state.transuser.filter(x => x.id === record.user ).length > 0 ? state.transuser.filter(x => x.id === record.user )[0].name : '无'
                    }
                </div>
            )
        },
        {
            title: '操作',
            width: 200,
            dataIndex: 'operation',
            align: 'right',
            width: '180px',
            render: (text, record) => (
                <div>
                    <Button size='small' type="primary" onClick={() => {
                        setShowFixModel(true)
                        //setActAddress(record)
                        dispatchAddress({ type: 'update', address: record })
                    }}>
                        修改
                    </Button>
                    <Button size='small' type='primary' danger style={{ marginLeft: '10px' }} onClick={() => {
                        Modal.confirm({
                            centered: true,
                            title: `确定要删除${state.addresstype.filter(x => { return x.code === record.type })[0].value }吗？`,
                            icon: <ExclamationCircleOutlined />,
                            content: `地址: ${record.address}删除之后将不可恢复！！`,
                            okText: '删除',
                            okType: 'danger',
                            cancelText: '取消',
                            onOk() {
                                http.delete("/v1/transaddress/" + record.id, { data: record }).then((data) => {
                                    http.get("/v1/transaddress", {}).then(data => {
                                        if (data.code === 0) {
                                            dispatch({ type: 'address', address: data.data })
                                        }
                                    })
                                })
                            },
                            onCancel() {
                                console.log('Cancel');
                            },
                        });
                        
                    }}>
                        删除
                    </Button>
                </div>
            ),
        },
    ]
    const columnsPerson = [
        // {
        //     title: '类型',
        //     dataIndex: 'type',
        //     align: 'left',
        //     render: (text, record) => (
        //         <div>
        //             {
        //                 state.addresstype.filter(x => { return x.code === record.type }).length > 0 ? state.addresstype.filter(x => { return x.code === record.type })[0].value : '无'
        //             }
        //         </div>
        //     )
        // },
        {
            title: '联系人',
            dataIndex: 'name',
            align: 'center',

        },
        {
            title: '联系方式',
            dataIndex: 'phone',
            align: 'center',
        },
        {
            title: '所属单位',
            dataIndex: 'user',
            align: 'center',
            render: (text, record) => (
                <div>
                    {
                        state.transuser.filter(x => x.id === record.user ).length > 0 ? state.transuser.filter(x => x.id === record.user )[0].name : '无'
                    }
                </div>
            )
        },
        {
            title: '操作',
            width: 200,
            dataIndex: 'operation',
            align: 'right',
            width: '180px',
            render: (text, record) => (
                <div>
                    <Button size='small' type="primary" onClick={() => {
                        setShowFixPModel(true)
                        let type = state.transuser.filter(x => x.id === record.user ).length > 0 ? state.transuser.filter(x => x.id === record.user )[0].type : 0;
                        console.log(type);
                        dispatchPerson({ type: 'update', person: { ...record, type: type }})
                    }}>
                        修改
                    </Button>
                    <Button size='small' type='primary' danger style={{ marginLeft: '10px' }} onClick={() => {
                        Modal.confirm({
                            centered: true,
                            title: `确定要删除${state.addresstype.filter(x => { return x.code === record.type })[0].value }吗？`,
                            icon: <ExclamationCircleOutlined />,
                            content: `联系人: ${record.name}删除之后将不可恢复！！`,
                            okText: '删除',
                            okType: 'danger',
                            cancelText: '取消',
                            onOk() {
                                http.delete("/v1/transaddress/" + record.id, { data: record }).then((data) => {
                                    http.get("/v1/transaddress", {}).then(data => {
                                        if (data.code === 0) {
                                            dispatchPerson({ type: 'person', person: data.data })
                                        }
                                    })
                                })
                            },
                            onCancel() {
                                console.log('Cancel');
                            },
                        });
                        
                    }}>
                        删除
                    </Button>
                </div>
            ),
        },
    ]


    return (
        <Row style={{overflow:'auto',height:'100%',}}>
            <Col span={12} style={{padding:10}}>
                <div style={{ float: 'right', marginBottom: 10 }}>
                    <Button style={{ backgroundColor: 'green', color: '#fff', borderRadius: 5 }}
                    onClick={() => {
                        setShowNewModel(true)
                        dispatchAddress({ type: 'clear',});
                        // var geocoder = new window.AMap.Geocoder({
                        //     // city 指定进行编码查询的城市，支持传入城市名、adcode 和 citycode
                        //     city: '全国'
                        // })
                        // var lnglat = [116.396574, 39.992706]
            
                        // geocoder.getAddress(lnglat, function(status, result) {
                        // if (status === 'complete' && result.info === 'OK') {
                        //     // result为对应的地理位置详细信息
                        //     console.log(result.regeocode.formattedAddress);
                        // }
                        // })

                    }}>新增地址</Button>
                </div>
                <Table bordered pagination={{ hideOnSinglePage: true }} rowKey={record => record.id} columns={columns} size='small' dataSource={state.address} />
                <Modal maskClosable={false} title="新增地址" visible={showNewModel}
                    destroyOnClose
                    cancelText='取消'
                    okText='确定'
                    onOk={() => {
                        http.post("/v1/transaddress", actAddress).then((data) => {
                            http.get("/v1/transaddress", {}).then(data => {
                                if (data.code === 0) {
                                    dispatch({ type: 'address', address: data.data })
                                }
                            })
                        })
                        // setShowNewModel(false)
                        dispatchAddress({ type: 'clear' })
                    }}
                    onCancel={() => {
                        setShowNewModel(false)
                        dispatchAddress({ type: 'clear'})
                    }}>
                    <AddressContext.Provider value={{ actAddress, dispatchAddress }}>
                        <AddressNew />
                    </AddressContext.Provider>
                </Modal>
                <Modal maskClosable={false} title="修改地址" visible={showFixModel}
                    destroyOnClose
                    cancelText='取消'
                    okText='确定'
                    onOk={() => {
                        http.put("/v1/transaddress/" + actAddress.id, actAddress).then((data) => {
                            http.get("/v1/transaddress", {}).then(data => {
                                if (data.code === 0) {
                                    dispatch({ type: 'address', address: data.data })
                                }
                            })
                        })
                        // setShowFixModel(false);
                        dispatchAddress({ type: 'clear' });
                    }}
                    onCancel={() => {
                        setShowFixModel(false)
                        dispatchAddress({ type: 'clear' });
                    }}>
                    <AddressContext.Provider value={{ actAddress, dispatchAddress }}>
                        <AddressNew />
                    </AddressContext.Provider>
                </Modal>
            </Col>
            <Col span={12} style={{padding:10}}>
                <div style={{ float: 'right', marginBottom: 10 }}>
                    <Button style={{ backgroundColor: 'green', color: '#fff', borderRadius: 5 }}
                    onClick={() => {
                        setShowNewPModel(true)
                        dispatchPerson({ type: 'clear', });

                    }}>新增联系人</Button>
                </div>
                <Table bordered pagination={{ hideOnSinglePage: true }} rowKey={record => record.id} columns={columnsPerson} size='small' dataSource={state.addressuser} />
                <Modal maskClosable={false} title="新增联系人" visible={showNewPModel}
                    destroyOnClose
                    cancelText='取消'
                    okText='确定'
                    onOk={() => {
                        http.post("/v1/transaddressuser", actPerson).then((data) => {
                            http.get("/v1/transaddressuser", {}).then(data => {
                                if (data.code === 0) {
                                    dispatch({ type: 'addressuser', addressuser: data.data })
                                }
                            })
                        })
                        // setShowNewModel(false)
                    }}
                    onCancel={() => {
                        setShowNewPModel(false)
                    }}>
                    <PersonContext.Provider value={{ actPerson, dispatchPerson }}>
                        <PersonNew />
                    </PersonContext.Provider>
                </Modal>
                <Modal maskClosable={false} title="修改联系人" visible={showFixPModel}
                    destroyOnClose
                    cancelText='取消'
                    okText='确定'
                    onOk={() => {
                        http.put("/v1/transaddressuser/" + actPerson.id, actPerson).then((data) => {
                            http.get("/v1/transaddressuser", {}).then(data => {
                                if (data.code === 0) {
                                    dispatch({ type: 'addressuser', addressuser: data.data })
                                }
                            })
                        })
                        // setShowFixModel(false);
                    }}
                    onCancel={() => {
                        setShowFixPModel(false)
                    }}>
                    <PersonContext.Provider value={{ actPerson, dispatchPerson }}>
                        <PersonNew />
                    </PersonContext.Provider>
                </Modal>
            </Col>
        </Row>
    )
}

export default AddressManage