import React, { createContext, useContext, useEffect, useState, useReducer } from 'react'
import { Button, Table, Input, Select, Modal } from 'antd'
import { StateContext } from '../../../state';
import http from '../../../../../utils/server';
import { ExclamationCircleOutlined } from '@ant-design/icons';

function SelectInput(props) {
    return (
        <div style={{ display: 'flex' }}>
            <div style={{ ...props.style, padding: '0 11px', paddingTop: 4, backgroundColor: '#fafafa', border: '1px solid #d9d9d9', borderRight: '0px', borderRadius: 2 }}>{props.addonBefore}</div>
            <Select disabled={props.disabled} value={props.defaultValue} style={{ ...props.style, flex: 1 }} onChange={(value) => { props.onChange && props.onChange(value) }}>
                {props.option.map((item, index) => (
                    <Select.Option key={index} value={item[props.keyword]}>{item[props.text]}</Select.Option>
                ))}
            </Select>
        </div>
    )
}
const TransuserContext = createContext();
function TransuserNew() {
    const { actTransuser, dispatchTransuser } = useContext(TransuserContext)
    const { state } = useContext(StateContext)
    useEffect(() => {

    }, [])
    return (
        <div>
            <SelectInput style={{ marginBottom: 10 }} addonBefore={<span style={{color:'red'}}>* <span style={{color:'#000'}}>类型:</span></span>} defaultValue={actTransuser.type} 
                option={state.tusertype?state.tusertype:[]} keyword={"code"} text='value'
                onChange={value => { dispatchTransuser({ type: 'update', transuser: { ...actTransuser, type: value } }) }} />
            <Input style={{ marginBottom: 10 }} addonBefore={<span style={{color:'red'}}>* <span style={{color:'#000'}}>名称:</span></span>} value={actTransuser.name}
                onChange={e => { dispatchTransuser({ type: 'update', transuser: { ...actTransuser, name: (e.target.value) } }) }} 
                />
            <Input style={{ marginBottom: 10 }} addonBefore={<span style={{color:'red'}}>* <span style={{color:'#000'}}>账号:</span></span>} value={actTransuser.account}
                onChange={(e) => { dispatchTransuser({ type: 'update', transuser: { ...actTransuser, account: (e.target.value) } }) }} />

            <Input style={{ marginBottom: 10 }} addonBefore={<span style={{color:'red'}}>* <span style={{color:'#000'}}>密码:</span></span>} value={actTransuser.password}
                onChange={(e) => { dispatchTransuser({ type: 'update', transuser: { ...actTransuser, password: (e.target.value) } }) }} />
            {/* <div style={{ display: 'none' }}>
                <div style={{padding: '0 11px', paddingTop: 4, backgroundColor: '#fafafa', border: '1px solid #d9d9d9', borderRight: '0px', borderRadius: 2 }}>{'所在用户'}</div>
                <TreeSelect  style={{ flex: 1 }}  treeDefaultExpandedKeys={defExpend} treeData={state.systemuser.treeM} onSelect={value=>console.log(value)}>
                    
                </TreeSelect>
            </div>     */}
        </div>
    )
}
function TransuserManager() {
    const { state, dispatch } = useContext(StateContext)
    const [actTransuser, dispatchTransuser] = useReducer((state, action) => {
        if (action.type === 'update') {
            return action.transuser
        } else if (action.type === 'clear') {
            return {
                name: '',
                account:'',
                password:'',
                users:action.users,
                type:'',
            }
        }
        return state
    }, []);
    const [showNewModel, setShowNewModel] = useState(false);
    const [showFixModel, setShowFixModel] = useState(false);
    useEffect(() => {
        http.get("/v1/tuserstype", {}).then(data => {
            if (data.code === 0) {
                dispatch({ type: 'tusertype', tusertype: data.data })
            }else{
                dispatch({ type: 'tusertype', tusertype: [] })
            }
        });
        http.get("/v1/users", {}).then(data => {
            if (data.code === 0) {
                dispatch({ type: 'systemuser', systemuser: data.data.sort((a,b)=>a.name.localeCompare(b.name,'zh')) })
            }else{
                dispatch({ type: 'systemuser', systemuser: [] })
            }
        });
        http.get("/v1/transuser", {}).then(data => {
            if (data.code === 0) {
                dispatch({ type: 'transuser', transuser: data.data })
            }else{
                dispatch({ type: 'transuser', transuser: [] })
            }
        });
        
    }, [])
    const columns=[
        {
            title: '名称',
            dataIndex: 'name',
            align: 'left',
        },
        {
            title: '账号',
            dataIndex: 'account',
            align: 'center',
        },
        {
            title: '密码',
            dataIndex: 'password',
            align: 'center',
        },
        {
            title: '类型',
            dataIndex: 'type',
            align: 'center',
            render: (text, record) => (
                <div>
                    {
                        record.type===901?'发货单位':record.type===902?'收货单位':record.type===903?'运输单位':null
                    }
                </div>
            )
        },
        {
            title: '操作',
            width: 200,
            dataIndex: 'transuser',
            align: 'right',
            width: '180px',
            render: (text, record) => (
                <div>
                    <Button size='small' type="primary" onClick={() => {
                        setShowFixModel(true)
                        //setActAddress(record)
                        dispatchTransuser({ type: 'update', transuser: record })
                    }}>
                        修改
                    </Button>
                    <Button size='small' type='primary' danger style={{ marginLeft: '10px' }} onClick={() => {
                        Modal.confirm({
                            centered: true,
                            title: `确定要删除<${state.tusertype.filter(x => { return x.code === record.type })[0].value }>吗？`,
                            icon: <ExclamationCircleOutlined />,
                            content: `名称:< ${record.name}>删除之后将不可恢复！！`,
                            okText: '删除',
                            okType: 'danger',
                            cancelText: '取消',
                            onOk() {
                                http.delete("/v1/transuser/" + record.id, { data: record }).then((data) => {
                                    http.get("/v1/transuser", {}).then(data => {
                                        if (data.code === 0) {
                                            dispatch({ type: 'transuser', transuser: data.data })
                                        }
                                    })
                                })
                            },
                            onCancel() {
                                console.log('Cancel');
                            },
                        });
                        
                    }}>
                        删除
                    </Button>
                </div>
            ),
        },
    ]
    return (
        <div style={{overflow:'auto',height:'100%',}}>
            <div style={{ float: 'right', marginBottom: 10 }}>
                <Button style={{backgroundColor:'green',color:'#fff',borderRadius:5}} 
                    onClick={() => {
                        setShowNewModel(true);
                        dispatchTransuser({ type: 'clear',users:state.main.id});
                }}>新建账号</Button>
            </div>
            <Table bordered pagination={{ hideOnSinglePage: true }} rowKey={record => record.id} columns={columns} size='small' dataSource={state.transuser} />
            <Modal maskClosable={false} title="新增账号" visible={showNewModel}
                destroyOnClose
                cancelText='取消'
                okText='确定'
                onOk={() => {
                    http.post("/v1/transuser", actTransuser).then((data) => {
                        http.get("/v1/transuser", {}).then(data => {
                            if (data.code === 0) {
                                dispatch({ type: 'transuser', transuser: data.data })
                            }
                        })
                    })
                    // setShowNewModel(false)
                    // dispatchTransuser({ type: 'clear' })
                }}
                onCancel={() => {
                    setShowNewModel(false)
                    dispatchTransuser({ type: 'clear' })
                }}>
                <TransuserContext.Provider value={{ actTransuser, dispatchTransuser }}>
                    <TransuserNew />
                </TransuserContext.Provider>
            </Modal>
            <Modal maskClosable={false} title="修改账号" visible={showFixModel}
                destroyOnClose
                cancelText='取消'
                okText='确定'
                onOk={() => {
                    http.put("/v1/transuser/" + actTransuser.id, actTransuser).then((data) => {
                        http.get("/v1/transuser", {}).then(data => {
                            if (data.code === 0) {
                                dispatch({ type: 'transuser', transuser: data.data })
                            }
                        })
                    })
                    // setShowFixModel(false);
                }}
                onCancel={() => {
                    setShowFixModel(false);
                }}>
                <TransuserContext.Provider value={{ actTransuser, dispatchTransuser }}>
                    <TransuserNew />
                </TransuserContext.Provider>
            </Modal>
        </div>

    )
}
export default TransuserManager