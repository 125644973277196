import axios from "axios";
import { notification } from 'antd';
import { Mode } from '../App';
let http = {
    post: '',
    get: ''
}

axios.interceptors.request.use(config => {
    config.headers = {
        "Content-Type": "application/json; charset=utf-8",
        'Authorization': `Bearer ${localStorage.getItem('token')}`
    };
    return config
}, (err) => {
    return Promise.reject(err);
})

http.post = function (api, data) {
    return new Promise((resolve, reject) => {
        var url ="";
        if(Mode==='local'){
            url="http://127.0.0.1:8086";        
        }
        axios.post(url+api, data).then((res) => {
            if (api.indexOf("/v1/") > -1 || api.indexOf("/v3/") > -1) {
                console.log('postres'+res);
                if (res.data.code == 0) {
                    notification['success']({
                        message: '操作成功',
                        description: '',
                        duration: 2,
                    });
                }else{
                    if(res.data.msg==='命令已缓存'){
                        notification['success']({
                            message: '操作成功',
                            description: res.data.msg,
                            duration: 2,
                        });
                    }else{
                        notification['error']({
                            message: '操作失败',
                            description: res.data.msg,
                            duration: 2,
                        });
                    }
                }
                // if (res.data.code == 200) {
                    
                // }
            }
            resolve(res.data)
        })
    })
}

http.get = function (api, data) {
    return new Promise((resolve, reject) => {
        var url ="";
        if(Mode==='local'){
            url="http://127.0.0.1:8086";        
        }
        axios.get(url+api, data).then((res) => {
            if (res.data.code == 999) {
                //密钥失败
                console.log("密钥过期")
                if (window.require) {
                    const electron = window.require('electron');
                    if (electron !== undefined) {
                        const { ipcRenderer } = electron;
                        ipcRenderer.send('close')
                    }
                }
            }
            resolve(res.data)
        })
    })
}

http.put = function (api, data) {
    return new Promise((resolve, reject) => {
        var url ="";
        if(Mode==='local'){
            url="http://127.0.0.1:8086";        
        }
        axios.put(url+api, data).then((res) => {
            if (res.data.code == 0) {
                notification['success']({
                    message: '操作成功',
                    description: '',
                    duration: 2,
                });
            }
            if (res.data.code == 200) {
                notification['error']({
                    message: '操作失败',
                    description: res.data.msg,
                    duration: 2,
                });
            }
            resolve(res.data)
        })
    })
}

http.delete = function (api, data) {
    return new Promise((resolve, reject) => {
        var url ="";
        if(Mode==='local'){
            url="http://127.0.0.1:8086";        
        }
        axios.delete(url+api, data).then((res) => {
            if (res.data.code == 0) {
                notification['success']({
                    message: '操作成功',
                    description: '',
                    duration: 2,
                });
            }
            if (res.data.code == 200) {
                notification['error']({
                    message: '操作失败',
                    description: res.data.msg,
                    duration: 2,
                });
            }
            resolve(res.data)
        })
    })
}

export default http