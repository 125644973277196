import { useEffect } from 'react';
import http from "./utils/server";
import RouteMap from "../src/routes/index";
export const Mode = 'soc'
// export const Mode = 'local'
let timer='';

function App() {
  useEffect(() => {
    http.get("/v1/main", {}).then(data=>{
      if(data.code === 0){
        localStorage.setItem("tokenis", true);
      }else{
        localStorage.clear()
      }
    })
    if(Mode==='local'){
      clearInterval(timer);
      timer = setInterval(()=>{
        http.get('/s/getreg',{}).then(data=>{
          if(data.code===0){
            window.CefSharp.BindObjectAsync("bound");
            window.bound.getReg(data.data);
            if(data.data==='已注册'){
              clearInterval(timer)
            }
          }
        })
      },5000)     
    }
    
    // return () => window.removeEventListener('resize', getResolution);
  },[])
  return <RouteMap></RouteMap>
}
export default App;