import React, { useState, useContext, useEffect } from 'react'
import { StateContext } from '../../../state';
import http from '../../../../../utils/server'
import { Row, Col, Button, Spin, Modal, Input, Space, Checkbox, DatePicker, Tabs, message, InputNumber, Switch} from 'antd'
import moment from 'moment';
import { ExclamationCircleOutlined} from '@ant-design/icons'
import './device.less'
import { Mode } from '../../../../../App';
import md5 from 'js-md5';
const { RangePicker } = DatePicker;
const { TabPane } = Tabs;

function formatTime(time){
    if(time){
       var strs= time.split(':');
       if(strs.length<2) return time;   
       strs[0].length===1&&(strs[0]='0'+strs[0]);
       strs[1].length===1&&(strs[1]='0'+strs[1]);
       return strs[0]+':'+strs[1];
    }
}
function DeviceSet(props) {
    const { state, dispatch } = useContext(StateContext)

    const [device, setDevice] = useState(props.item)
    const [serverIP, setServerIP] = useState({ validity: false, error: false, type: 1 })
    const [deviceTime, setDeviceTime] = useState({ validity: false, error: false, type: 2 })
    const [uploadInterval, setUploadInterval] = useState({ validity: false, error: false, type: 3 })
    const [sensorStop, setSensorStop] = useState({ validity: false, error: false, type: 4 })

    const [store, setStore] = useState({ validity: false, error: false, type: 9 })
    const [revise, setRevise] = useState({ validity: false, error: false, type: 10 })
    const [devClose, setDevClose] = useState({ validity: true, error: false, type: 11 })
    const [devStronInfo, setDevStronInfo] = useState({ validity: true, error: false, type: 12})
    const [load, setLoad] = useState(false)

    const [timeReadDataStart,setTimeReadDataStart] = useState({ validity: false, error: false, value:"" })
    const [timeReadDataEnd,setTimeReadDataEnd] = useState({ validity: false, error: false, value:"" })
    const [offline,setOffLine] = useState({offtime:10,interval:5,count:2});
    const [showAffirm,setShowAffirm] = useState(false);//设置参数身份确认弹窗
    const [affirmPwd,setAffirmPwd] = useState(''); //设置参数确认密码
    const [setState, setSetState] = useState(0);//设置状态 0不能设置 1普通设置 2超级设置
    const [isSet,setIsSet] = useState(false);//是否有设置权限
    const [isMore,setIsMore] = useState(false);//是否多测点设置
    const [devList, setDevList] = useState([]);//选中的主机名
    useEffect(() => {
        let today =  new Date().setHours(0,0,0);
        let todayTime = new Date(today).getTime();
        setSetState(state.setState);
        let role = state.main.role;
        setIsSet(role.match('305')===null?false:true)
        let devs = 
        state.main.type<102?  state.device.filter(x=>x.node!==0&&x.district==props.item.District&&
            x.type===207)
        : 
        state.device.filter(x=>x.node!==0&&x.district==props.item.District&&new Date(x.expire).getTime()>todayTime&&
        x.type===207);
        let devNodes = [];
        devs.map(m=>{
            let temp = devNodes.findIndex(x=>x.sn===m.sn)
            if(temp===-1){
                devNodes.push({sn:m.sn,name:m.m_name,nodes:[{value:m.id,label:m.name,key:m.node,checked:false}],all:false,indeterminate:false})
            }else{
                devNodes[temp].nodes.push({value:m.id,label:m.name,key:m.node,checked:false})
            }
        })
        if(devNodes.length>0){
            devNodes[0].all = true
            devNodes[0].nodes.map(m=>{
                m.checked = true
            })
        }
        setDevList(devNodes)
    }, [])


    const ReadDeviceParams = (t, call) => {
        setLoad(true)
        http.post((props.cloud ? "/v3" : "/v1") + "/getdevice", { ...t, device: device.Sn, node: device.Node }).then(data => {
            setLoad(false)
            if (data.code == 0) {
                if(data.data.Params.OverAlarm.StartTime!==""){
                    data.data.Params.OverAlarm.StartTime= formatTime(data.data.Params.OverAlarm.StartTime);
                    data.data.Params.OverAlarm.EndTime= formatTime(data.data.Params.OverAlarm.EndTime);
                }
                data.data.Name = device.Name;
                data.data.MName = device.MName;
                setDevice(data.data);
                call({ ...t, validity: true });
            }
        })
    }
    const ReadDeviceData = () => {
        if(!timeReadDataStart.validity){
            Modal.error({
                title: '参数错误',
                centered: true,
                content: '请检查所设置的参数',
            });
            return
        }
        if(!timeReadDataEnd.validity){
            Modal.error({
                title: '参数错误',
                centered: true,
                content: '请检查所设置的参数',
            });
            return
        }
        setLoad(true)
        http.post((props.cloud ? "/v3" : "/v1") + "/readdevicedata", {  
            device: device.Sn, 
            node: device.Node,
            starttime:timeReadDataStart.value ,
            endtime:timeReadDataEnd.value
        }).then(data => {
            setLoad(false)
            if (data.code == 0) {

            }
        })
    }
    const SetDeviceOffLineAlarm = (t) => {
        setLoad(true)
        http.put((props.cloud ? "/v3" : "/v1") + "/deviceoffline/" + device.ID, { alarm: t.alarm ,offtime:t.offtime,interval:t.interval,count:t.count}).then(data => {
            setLoad(false)
            if (data.code == 0) {
                console.log(t);
                device.Sensor.OffLineAlarm=t.alarm;
                device.Sensor.OffLineTime=t.offtime;
                device.Sensor.OffLineAlarmInterval=t.interval;
                device.Sensor.OffLineAlarmCount=t.count;
                setDevice({ ...device})
            }
        })
    }
    const SetDeviceLinkAlarm = (t) => {
        setLoad(true)
        http.put((props.cloud ? "/v3" : "/v1") + "/devicelink/" + device.ID, { alarm: t }).then(data => {
            setLoad(false)
            if (data.code == 0) {
                device.Sensor.LinkAlarm=t;
                setDevice({ ...device})
            }
        })
    }
    const SetDeviceParams = (t) => {
        if (t.error) {
            Modal.error({
                title: '参数错误',
                centered: true,
                content: '请检查所设置的参数',
            });
        } else {
            setLoad(true)
            if(isMore){
                let devInfo = device;
                let checkedList = []
                devList.map(m=>{
                    m.nodes.map(mm=>{
                        if(mm.checked){
                            checkedList.push({sn:m.sn,node:mm.key})
                        }
                    })
                })
                let countMax = checkedList.length;
                let index = 0;
                let timer= setInterval(()=>{
                    console.log('starttimer',timer);
                    if(index<countMax){
                        devInfo.Node = checkedList[index].node
                        devInfo.Sn = checkedList[index].sn
                        http.post((props.cloud ? "/v3" : "/v1") + "/setdevice", { ...t, device: devInfo }).then(data => {
                            setLoad(false)
                        })
                    }else{
                        clearInterval(timer)
                    }
                    index++;
                },1000)
            }else{
                setTimeout(() => {
                    http.post((props.cloud ? "/v3" : "/v1") + "/setdevice", { ...t, device: device }).then(data => {
                        setLoad(false)
                    })
                }, 1000);
            }
        }
   
    }
    const ReadDeviceWarnParams = t=>{
        if (t.validity) {
            if (t.error) {
                Modal.error({
                    title: '参数错误',
                    centered: true,
                    content: '请检查所设置的参数',
                });
            } else {
                setLoad(true)
                http.post((props.cloud ? "/v3" : "/v1") + "/setdevice", { ...t, device: device }).then(data => {
                    setLoad(false)
                })
            }
        } else {
            Modal.error({
                title: '操作错误',
                centered: true,
                content: '设置之前必须先读取参数',
            });
        }
    }
    return (
        <div>
            <Tabs defaultActiveKey="1" onChange={e=>{
                setIsMore(e==='1'?false:true)
            }}tabBarExtraContent={<Button onClick={()=>setShowAffirm(true)} disabled={!isSet}>设置参数</Button>}>
                <TabPane tab="单测点设置" key="1" >
                    <div style={{ display: 'flex',  justifyContent: 'space-between', alignItems: 'center', padding: '0px 20px' }}>
                        <span>{device.Name}</span>
                        <span>{' 主机名：' + device.MName}</span>
                        <span>{' 主机编号：' + device.Sn}</span> 
                        <span>{' 测点ID: ' + device.Node}</span> 
                    </div>  
                </TabPane>
                {/* <TabPane tab="多测点设置" key="2">
                    <div style={{ padding: '0px 20px',overflow:'scroll' ,maxHeight:300,}}> 
                        {
                            devList.map(m=>{
                                return(
                                <div style={{display:'flex',flexDirection:'row'}} >
                                    <div style={{whiteSpace:'nowrap',paddingBottom:20}}>
                                        <span style={{ marginLeft: '10px',color:'#1890ff' }}>主机名:</span> 
                                        <span style={{ fontWeight: 'normal', fontSize: '16px' }}>{m.name}</span>
                                    </div>
                                    <div style={{whiteSpace:'nowrap',paddingBottom:20}}>
                                        <span style={{ marginLeft: '10px' ,color:'#1890ff'}}>主机编号:</span> 
                                        <span style={{ fontWeight: 'normal', fontSize: '16px' }}>{m.sn}</span>
                                    </div>
                                    
                                    <div style={{whiteSpace:'nowrap',paddingBottom:20}}>
                                        <span margin-left='14px' style={{ fontSize: 14, marginLeft: 10,color:'#1890ff' }}>测点</span>
                                        <Checkbox indeterminate={m.indeterminate} onChange={e=>{
                                            m.indeterminate = false
                                            if(e.target.checked){
                                                m.nodes.map(n=>{
                                                    n.checked = true;
                                                })
                                                m.all = true;
                                            }else{
                                                m.all = false;
                                                m.nodes.map(n=>{
                                                    n.checked = false
                                                })

                                            }
                                            let templist = devList.concat()
                                            setDevList(templist)
                                        }} checked={m.all}>
                                            全选
                                        </Checkbox>
                                    </div>
                                    <div style={{whiteSpace:'nowrap',paddingBottom:20}}>
                                    {
                                        m.nodes.map(mm=>{
                                            return(
                                                <Checkbox value={mm.value} checked={mm.checked}
                                                onChange={e=>{
                                                    if(e.target.checked){
                                                        mm.checked = true;
                                                    }else{
                                                        mm.checked = false
                                                      
                                                    }
                                                    let ttt=devList.filter(x=>x.sn===m.sn)[0].nodes
                                                    let checkNodelen= ttt.filter(x=>x.checked).length
                                                    if(checkNodelen===0||checkNodelen===ttt.length){
                                                        m.indeterminate = false
                                                    }else{
                                                        m.indeterminate = true
                                                    }
                                                    if(checkNodelen===ttt.length){
                                                        m.all = true
                                                    }else{
                                                        m.all = false
                                                    }
                                                    let templist=devList.concat()
                                                    setDevList(templist)
                                                }}
                                                >{mm.label}</Checkbox>
                                            )
                                        })
                                    }
                                    </div>
                                </div>)
                            })
                        }
                        
                    </div>  
                    
                </TabPane> */}
            </Tabs> 

            <Tabs defaultActiveKey="1">
                <TabPane tab="报警设置" key="1">
                    <div style={{height:'600px'}}>
                        <Spin spinning={load}>
                        <Row gutter={[10, 10]} style={{ position: 'relative' }}>
                            {/* 温湿度预警 */}
                            {
                                device.Node!==0&&
                                <Col span={24} style={{ display: 'flex', alignItems: 'center' }}>
                                    <div>报警参数：</div>
                                    {/* <Space> */}
                                    <Input.Group style={{ width: 420 }} compact>
                                        <Input defaultValue={device.Sensor.TempWarningHigh} addonBefore={"温度上限"} addonAfter={"℃"} style={{ width: 200, margin: 5 }} onChange={e => {
                                            var reg = /^(-?\d+)(\.\d+)?$/
                                            if (reg.test(e.target.value)) {
                                                setDevice({ ...device, Sensor: { ...device.Sensor, TempWarningHigh: parseFloat(e.target.value) } })
                                            } else {
                                                setDevice({ ...device, Sensor: { ...device.Sensor, TempWarningHigh: 0 } })
                                            }
                                        }} />
                                        <Input defaultValue={device.Sensor.TempWarningLow} addonBefore={"温度下限"} addonAfter={"℃"} style={{ width: 200, margin: 5 }} onChange={e => {
                                            var reg = /^(-?\d+)(\.\d+)?$/
                                            if (reg.test(e.target.value)) {
                                                setDevice({ ...device, Sensor: { ...device.Sensor, TempWarningLow: parseFloat(e.target.value) } })
                                            } else {
                                                setDevice({ ...device, Sensor: { ...device.Sensor, TempWarningLow: 0 } })
                                            }
                                        }} />
                                        <Input defaultValue={device.Sensor.HumiWarningHigh} addonBefore={"湿度上限"} addonAfter={"%RH"} style={{ width: 200, margin: 5 }} onChange={e => {
                                            var reg = /^(-?\d+)(\.\d+)?$/
                                            if (reg.test(e.target.value)) {
                                                setDevice({ ...device, Sensor: { ...device.Sensor, HumiWarningHigh: parseFloat(e.target.value) } })
                                            } else {
                                                setDevice({ ...device, Sensor: { ...device.Sensor, HumiWarningHigh: 0 } })
                                            }
                                        }}  disabled={device.Humi===0}/>
                                        <Input defaultValue={device.Sensor.HumiWarningLow} addonBefore={"湿度下限"} addonAfter={"%RH"} style={{ width: 200, margin: 5 }} onChange={e => {
                                            var reg = /^(-?\d+)(\.\d+)?$/
                                            if (reg.test(e.target.value)) {
                                                setDevice({ ...device, Sensor: { ...device.Sensor, HumiWarningLow: parseFloat(e.target.value) } })
                                            } else {
                                                setDevice({ ...device, Sensor: { ...device.Sensor, HumiWarningLow: 0 } })
                                            }
                                        }} disabled={device.Humi===0}/>
                                        <Input defaultValue={device.Sensor.WarningInterval} addonBefore={"报警间隔"} addonAfter={"分钟"} style={{ width: 200, margin: 5 }}
                                        onChange={e => {
                                            var reg = /^[0]|[1-9]\d{0,2}$/
                                            if (reg.test(e.target.value)) {
                                                setDevice({ ...device, Sensor: { ...device.Sensor, WarningInterval: parseInt(e.target.value) } })
                                            } else {
                                                setDevice({ ...device, Sensor: { ...device.Sensor, WarningInterval: 2 } })
                                            }
                                        }} />
                                    </Input.Group>
                                    <div style={{ flex: 1 }}></div>
                                    <Space>
                                        {
                                            setState>0&&
                                            <Button onClick={()=>{
                                                setLoad(true);
                                                http.put((props.cloud ? "/v3" : "/v1") + "/devicewarning/" + device.ID, { 
                                                    temp_waning_high: device.Sensor.TempWarningHigh, 
                                                    temp_waning_low:device.Sensor.TempWarningLow,
                                                    humi_waning_high:device.Sensor.HumiWarningHigh,
                                                    humi_waning_low:device.Sensor.HumiWarningLow,
                                                    warning_interval:device.Sensor.WarningInterval
                                                }).then(data=>{
                                                    setLoad(false);
                                                    if(data.code===0){
                                                        setDevice({
                                                            ...device,
                                                            TempWarningHigh:device.Sensor.TempWarningHigh,
                                                            TempWarningLow:device.Sensor.TempWarningLow,
                                                            HumiWarningHigh:device.Sensor.HumiWarningHigh,
                                                            HumiWarningLow:device.Sensor.HumiWarningLow,
                                                            WarningInterval:device.Sensor.WarningInterval
                                                        })
                                                    }
                                                })
                                            }} >设置</Button>
                                        }
                                    </Space>
                                </Col>
                            }
                            {
                                device.Node===0&&
                                <Col span={24} style={{ display: 'flex', alignItems: 'center' }}>
                                <div>除湿参数：</div>
                                <Input.Group style={{ width: 420 }} compact>
                                <Input value={device.Sensor.DeHumiHigh} addonBefore={"湿度上限"} addonAfter={"%RH"} style={{ width: 200, margin: 5 }} onChange={e => {
                                        var reg = /^(-?\d+)(\.\d+)?$/
                                        if (reg.test(e.target.value)) {
                                            setDevice({ ...device, Sensor: { ...device.Sensor, DeHumiHigh: parseFloat(e.target.value) } })
                                        } else {
                                            setDevice({ ...device, Sensor: { ...device.Sensor, DeHumiHigh: (e.target.value) } })
                                        }
                                    }} disabled={device.Humi===0}/>
                                    <Input value={device.Sensor.DeHumiLow} addonBefore={"湿度下限"} addonAfter={"%RH"} style={{ width: 200, margin: 5 }} onChange={e => {
                                        var reg = /^(-?\d+)(\.\d+)?$/
                                        if (reg.test(e.target.value)) {
                                            setDevice({ ...device, Sensor: { ...device.Sensor, DeHumiLow: parseFloat(e.target.value) } })
                                        } else {
                                            setDevice({ ...device, Sensor: { ...device.Sensor, DeHumiLow: (e.target.value) } })
                                        }
                                    }} disabled={device.Humi===0}/>
                                </Input.Group>
                                <div style={{ flex: 1 }}></div>
                                <Space>
                                    {
                                        setState>0&&
                                        <Button onClick={() => {
                                            if(device.Sensor.DeHumiHigh<device.Sensor.DeHumiLow) return message.warn('参数错误,下限值应低于上限值')
                                            http.put((props.cloud ? "/v3" : "/v1") + "/devicedehumi/" + device.ID, { 
                                                dehumi_high:device.Sensor.DeHumiHigh,
                                                dehumi_low:device.Sensor.DeHumiLow,
                                            }).then(data=>{
                                                setLoad(false);
                                                if(data.code===0){
                                                    setDevice({
                                                        ...device,
                                                        Sensor:{...device.Sensor,DeHumiHigh:device.Sensor.DeHumiHigh,DeHumiLow:device.Sensor.DeHumiLow}
                                                    })
                                                }
                                            })
                                        }} disabled={Mode!=='soc'&&state.main.type===104}>设置</Button>
                                    }
                                    
                                </Space>
                            </Col>
                            }
                            {
                                device.Node===0&&
                                <Col span={24} style={{ display: 'flex', alignItems: 'center' }}>
                                    <div>除湿方式：</div>
                                    <span style={{marginRight:20}}>手动</span><Switch checked={device.Sensor.DeHumiMode===0} onChange={checked=>{
                                        http.put((props.cloud ? "/v3" : "/v1") + "/devicedehumimode/" + device.ID, { 
                                            dehumimode:checked?0:1
                                        }).then(data=>{
                                            setLoad(false);
                                            if(data.code===0){
                                                setDevice({ ...device, Sensor: { ...device.Sensor, DeHumiMode:checked?0:1 } })
                                            }
                                        })
                                    }}/><span style={{marginLeft:20}}>自动</span>
                                    
                                </Col>
                            }
                            {
                                device.Node===0&&
                                <Col span={24} style={{ display: 'flex', alignItems: 'center' }}>
                                    <div>手动除湿：</div>
                                    <Button onClick={() => {
                                        http.put((props.cloud ? "/v3" : "/v1") + "/devicedehumistatus/" + device.ID, { 
                                            dehumistatus:device.Sensor.DeHumiStatus===0?1:0
                                        }).then(data=>{
                                            setLoad(false);
                                            if(data.code===0){
                                                setDevice({ ...device, Sensor: { ...device.Sensor, DeHumiStatus:device.Sensor.DeHumiStatus===0?1:0 } })
                                            }
                                        })
                                    }} disabled={device.Sensor.DeHumiMode===0}>{device.Sensor.DeHumiStatus===1?'已开启':'已关闭'}</Button>
                                </Col>
                            }
                            
                        </Row>
                    </Spin>
                    </div>
                </TabPane>
                <TabPane tab="基本设置" key="2">
                    <div style={{height:'600px'}}>
                        <Spin spinning={load}>
                        <Row gutter={[10, 20]} style={{ position: 'relative' }}>
                            {/* <Divider orientation="left">基本设置</Divider> */}
                            <Col span={12} style={{ display: 'flex' }}>
                                <Space>
                                    <Input style={{width:260}} value={device.Params.IPAddress} addonBefore={"IP地址："} placeholder='xxx.xxx.xxx.xxx:5566' onChange={e => {
                                        setDevice({ ...device, Params: { ...device.Params, IPAddress: e.target.value } })
                                    }} />
                                    <Button onClick={() => {
                                            ReadDeviceParams(serverIP, setServerIP);
                                        }}>读取</Button>
                                    {
                                        setState>0&&
                                        <Button onClick={() =>{ SetDeviceParams(serverIP);}} disabled={Mode!=='soc'&&state.main.type === 104}>设置</Button>
                                    }
                                    
                                </Space>
                            </Col>
                            {
                               setState>1&&
                                <Col span={12} style={{ display: 'flex' }}>
                                <Space>
                                    <div>补偿：</div><div style={{color: '#1890ff'}}>温度：</div>
                                    <InputNumber style={{width:80,margin:0}}  value = {device.Sensor.TempCompensate} placeholder='0' onChange={e => {
                                        let value = e;
                                        setDevice({ ...device, Sensor: { ...device.Sensor, TempCompensate: value } })
                                    }} />
                                    <div style={{color: '#1890ff'}}>湿度：</div>
                                    <InputNumber style={{width:80}} value={device.Sensor.HumiCompensate}  placeholder='0' onChange={e => {

                                        setDevice({ ...device, Sensor: { ...device.Sensor, HumiCompensate:e } })
                                    }}  />
                                    <Button onClick={() => {ReadDeviceParams(revise, setRevise);}}>读取</Button>
                                    {
                                        setState>0&&
                                        <Button onClick={() =>{ SetDeviceParams(revise); }}  >设置</Button>
                                    }
                                    
                                </Space>
                                </Col>
                            }
                            <Col span={24} style={{ display: 'flex' }}>
                                <Space>
                                    <Input style={{width:260}} value={device.Params.RealTimeInt} addonBefore={"回传间隔："} addonAfter={"秒"} onChange={e => {
                                        var reg = /^[\d]{0,4}$/
                                        if (reg.test(e.target.value)) {
                                            uploadInterval.error = false
                                            setDevice({ ...device, Params: { ...device.Params, RealTimeInt: Number(e.target.value) } })
                                        } else {
                                            uploadInterval.error = true
                                            setDevice({ ...device, Params: { ...device.Params, RealTimeInt: (e.target.value) } })
                                        }
                                    }} />
                                    <Button onClick={() => {ReadDeviceParams(uploadInterval, setUploadInterval);}}>读取</Button>
                                    {
                                        setState>0&&
                                        <Button onClick={() => {SetDeviceParams(uploadInterval);}} disabled={Mode!=='soc'&&state.main.type === 104}>设置</Button>
                                    }
                                </Space>
                            </Col>
                            <Col span={24} style={{ display: 'flex'}}>
                                <Space>
                                    <Input value={device.Params.DeviceTime} addonBefore={"设备时间："} disabled />
                                    <Button onClick={() => {ReadDeviceParams(deviceTime, setDeviceTime);}}>读取</Button>
                                    {
                                        setState>0&&
                                        <Button onClick={() =>{SetDeviceParams(deviceTime);} } disabled={Mode!=='soc'&&state.main.type === 104}>设置</Button>
                                    }
                                </Space>
                            </Col>
                            

                            <Col span={24} style={{ display: 'flex' }}>
                                <Space>
                                    <Input addonBefore={"设备操作："} disabled value={"请谨慎操作"} />
                                    <Button onClick={()=>{
                                        setDevClose({...device, Params: {...device.Params}});
                                        SetDeviceParams(devClose)}} disabled={Mode!=='soc'&& state.main.type === 104}>关机</Button>
                                    {/* <Button>重启</Button> */}
                                </Space>
                            </Col>
                            <Col span={24} style={{ display: 'flex' }}>
                                <Space>
                                    <div style={{ flexDirection: 'row', display: 'flex' }}>
                                        <span style={{ border: '1px solid #ccc', display: 'inline-block', width: 110, margin: 0, padding: 5, borderRightColor: 'transparent' }}>读取历史数据：</span>
                                        <RangePicker style={{ margin: 0 }}
                                            ranges={{
                                                今天: [moment().startOf('day'), moment()],
                                                '本周': [moment().startOf('week'), moment().endOf('week')],
                                                '本月': [moment().startOf('month'), moment().endOf('month')],
                                            }}
                                            showTime
                                            format="YYYY-MM-DD HH:mm"
                                            onChange={(dates, dateStrings) => {
                                                // console.log(dateStrings)
                                                if (dateStrings[0] !== "") {
                                                    setTimeReadDataStart({ validity: true, error: false, value:dateStrings[0] })
                                                    setTimeReadDataEnd({ validity: true, error: false, value:dateStrings[1] })
                                                }
                                            }}
                                        />
                                    </div>

                                    <Button onClick={() => {ReadDeviceData();}}>读取</Button>
                                </Space>
                            </Col>

                            
                            <Col span={24} style={{ display: 'flex' }}>
                                <Space>
                                    <Input value={device.Params.NorStoreInt} addonBefore={"正常存储间隔："} addonAfter={"分钟"} onChange={e => {
                                        var reg = /^[0-9]*[1-9][0-9]*$/
                                        if (reg.test(e.target.value)) {
                                            store.error = false
                                            setDevice({ ...device, Params: { ...device.Params, NorStoreInt: Number(e.target.value) } })
                                        } else {
                                            store.error = true
                                            setDevice({ ...device, Params: { ...device.Params, NorStoreInt: (e.target.value) } })
                                        }
                                    }} />
                                    <Input value={device.Params.AlmStoreInt} addonBefore={"报警存储间隔："} addonAfter={"分钟"} onChange={e => {

                                        var reg = /^[0-9]*[1-9][0-9]*$/
                                        if (reg.test(e.target.value)) {
                                            store.error = false
                                            setDevice({ ...device, Params: { ...device.Params, AlmStoreInt: Number(e.target.value) } })
                                        } else {
                                            store.error = true
                                            setDevice({ ...device, Params: { ...device.Params, AlmStoreInt: (e.target.value) } })
                                        }
                                    }}/>
                                    <Button onClick={() => {ReadDeviceParams(store, setStore);}}>读取</Button>
                                    {
                                        setState>0&&
                                        <Button onClick={() => {SetDeviceParams(store);}} disabled={Mode!=='soc'&&state.main.type === 104}>设置</Button>
                                    }
                                </Space>
                            </Col>
                            <Col span={12} style={{ display: 'flex', alignItems: 'center', }}>
                                <div>探头停用：</div>
                                <Checkbox checked={device.Sensor.Enable == 1 ? true : false} onChange={e => {
                                    setDevice({ ...device, Sensor: { ...device.Sensor, Enable: e.target.checked ? 1 : 0 } })
                                }}>停用</Checkbox>
                                <Space>
                                    <Button onClick={() => {ReadDeviceParams(sensorStop, setSensorStop);}}>读取</Button>
                                    {
                                        setState>0&&
                                        <Button onClick={() => {SetDeviceParams(sensorStop);}} disabled={Mode!=='soc'&&state.main.type === 104}>设置</Button>
                                    }
                                </Space>
                            </Col>
                            <Col span={12} >
                                
                                <div>探头连接失败报警：<Button onClick={() => {
                                    SetDeviceLinkAlarm(device.Sensor.LinkAlarm === 0 ? 1 : 0);
                                }}>{device.Sensor.LinkAlarm === 0 ? "已关闭" : "已打开"}</Button></div>
                            </Col>
                            <Col span={20} style={{display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                                <div>离线报警：</div>
                                <Input defaultValue={device.Sensor.OffLineTime}  addonBefore={"离线间隔："} addonAfter={'分'} style={{width:200}} autoComplete='off'
                                onChange={e=>{
                                    setOffLine({...offline,offtime:e.target.value*1})
                                }}/>
                                <Input defaultValue={device.Sensor.OffLineAlarmInterval}  addonBefore={"报警间隔："} addonAfter={'分'} style={{width:200}} autoComplete='off'
                                onChange={e=>{
                                    setOffLine({...offline,interval:e.target.value*1})
                                }}/>
                                <Input defaultValue={device.Sensor.OffLineAlarmCount}  addonBefore={"报警次数："} style={{width:160}} autoComplete='off'
                                onChange={e=>{
                                    setOffLine({...offline,count:e.target.value*1})
                                }}/>
                                <Button onClick={() => {
                                    SetDeviceOffLineAlarm({...offline,alarm:device.Sensor.OffLineAlarm === 0 ?1:0});
                                }}>{device.Sensor.OffLineAlarm === 0 ? "已关闭" : "已打开"}</Button>
                                
                            </Col>
                            {
                                setState>1&&
                                <Col span={24} style={{ display: 'flex',alignItems:'center' }}>
                                <div>存储信息：</div>
                                <Button onClick={() => {ReadDeviceParams(devStronInfo, setDevStronInfo);}}>读取</Button>
                                <Button style={{marginLeft:10}} onClick={() => {
                                    Modal.confirm({
                                        centered: true,
                                        title: `确定要清除设备存储信息吗？`,
                                        icon: <ExclamationCircleOutlined />,
                                        content: `设备${device.Sn}的存储信息清除之后将不可恢复！！`,
                                        okText: '清除',
                                        okType: 'danger',
                                        cancelText: '取消',
                                        onOk() {
                                            SetDeviceParams(devStronInfo, setDevStronInfo);
                                        },
                                        onCancel() {
                                            console.log('Cancel');
                                        },
                                    });
                                    
                                    }}>清除</Button>
                                <div style={{marginLeft:50}}>当前存储数据的扇区:{device.StoreInfo.CurrSector}</div>
                                <div style={{marginLeft:50}}>当前存储数据的扇区ADDRESS:{device.StoreInfo.CurrSectorAddr}</div>

                            </Col>
                            }
                            {
                                setState>1&&
                                <Col span={24} style={{ display: 'flex',justifyContent:'space-between', }}>
                                <div>读取存储数据的扇区:{device.StoreInfo.ReadSector}</div>
                                <div>读取存储数据的扇区ADDRESS:{device.StoreInfo.ReadSectorAddr}</div>
                                <div>大批量读取数据的起始扇区:{device.StoreInfo.LargeSector}</div>
                            </Col>
                            }
                           
                        </Row>
                    </Spin>
                    </div>
                </TabPane>
            </Tabs>
            <Modal title='身份认证' centered visible={showAffirm} destroyOnClose={true} 
            onOk={()=>{
                let s = 0
                if(affirmPwd.startsWith("super")){
                    if(md5(affirmPwd.slice(5))===state.main.password){
                        s=2;
                        setShowAffirm(false);
                    }else{
                        s=0;
                        message.warn('密码错误');
                    }
                }else{
                    if(md5(affirmPwd)===state.main.password){
                        s=1;
                        setShowAffirm(false);
                    }else{
                        s=0;
                        message.warn('密码错误');
                    }
                }
                setSetState(s);
                props.changeSetState(s);
                dispatch({type:'super',super:s})
                dispatch({type:'setState',setState:s===0?0:1})
            }}  onCancel={()=>setShowAffirm(false)}>
                <Input.Password addonBefore='输入密码:' onChange={e=>setAffirmPwd(e.target.value)} />
            </Modal>
        </div>
    )
}

export default DeviceSet