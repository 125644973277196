import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App.jsx';
import reportWebVitals from './reportWebVitals';
// import RouteMap from "../src/routes/index";
// import { BrowserRouter as Router } from 'react-router-dom';
// import RenderRoutes from "../src/routes/renderRoutes";



ReactDOM.render(
     <App></App> ,
  document.getElementById('root')
);


reportWebVitals();
