import React, { createContext, useContext, useEffect, useState, useReducer} from 'react'
import { Table, Button, Input,  Modal, Space,  Form, } from 'antd'
import { StateContext } from '../../../state';
import http from '../../../../../utils/server'
import { SearchOutlined } from '@ant-design/icons';
import './upDev.less'

const DeviceContext = createContext();

function DeviceFix() {
    const { actDevice, dispatchDevice, form, onfinish } = useContext(DeviceContext)
    const { state } = useContext(StateContext)
    const formRef = React.createRef();
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 4 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 20 },
        },
    };
    useEffect(() => {
        let devTypetemp = state.devicetype.filter(x=>x.code===actDevice.type);
        let devType = devTypetemp.length>0&&devTypetemp[0].value;
        form.setFieldsValue({
            ...actDevice,
            type:devType
        })
    }, [])

    return (
        <Form
            form={form}
            {...formItemLayout}
            ref={formRef}
            onFinish={onfinish}>
            <Form.Item
                label="所属区域"
                name="district_name"
                rules={[{ required: true, message: '选择所属区域' }]}>
                <Input disabled={true} />
            </Form.Item>
            <div style={{ display: 'flex' }}>
                <Form.Item style={{ width: 300 }}
                    labelCol={{ span: 8 }}
                    label="主机类型"
                    name="type"
                    rules={[{ required: true, message: '选择主机类型' }]}>
                    <Input disabled={true} />
                </Form.Item>
                <Form.Item style={{ width: 300 }}
                    labelCol={{ span: 8 }}
                    label="主机编号"
                    name="sn">
                    <Input disabled={true} />
                </Form.Item>
            </div>
            <Form.Item
                label="主机名称"
                name="m_name"
                rules={[{ required: true, message: '输入主机名称' }]}>
                <Input disabled={true}/>
            </Form.Item>
            <div style={{ display: 'flex' }}>
                <Form.Item style={{ width: 400 }}
                    labelCol={{ span: 6 }}
                    label="测点名称"
                    name="name"
                    rules={[{ required: true, message: '输入测点名称' }]}>
                    <Input disabled={true}/>
                </Form.Item>
                <Form.Item style={{ width: 200 }}
                    labelCol={{ span: 10 }}
                    label="测点ID"
                    name="node"
                    rules={[{ required: true, message: '输入测点ID' }]}>
                    <Input disabled={true}/>
                </Form.Item>
            </div>
            <Form.Item
                label='上传平台编号'
                name='upSn'
            >
                <Input/>
            </Form.Item>
        </Form>
    )
}
function UpDevManage() {
    const [form] = Form.useForm()
    const { state, dispatch } = useContext(StateContext)
    const [actDevice, dispatchDevice] = useReducer((state, action) => {
        if (action.type === 'update') {
            return action.device
        }
        return state
    }, []);
    const [showFixModel, setShowFixModel] = useState(false)
    const [tableData, setTableData] = useState([]);//显示表格的数据源
    const [disFilter,setDisFilter] = useState([]);
    const [devTypeFilter,setDevTypeFilter] = useState([]);
    const nodeSerch = React.createRef();
    const devSerch = React.createRef();
    useEffect(() => {
        http.get("/v1/device", {}).then(data => {
            if (data.code === 0) {
                let temp = data.data.filter(x=>x.node!==0).sort((a,b)=>a.sn-b.sn)
                setTableData(temp);
                dispatch({ type: 'device', device: temp });
            }
        })
        http.get("/v1/devicetype", {}).then(data => {
            if (data.code === 0) {
                dispatch({ type: 'devicetype', devicetype: data.data });
                let arrs=[];
                data.data&&data.data.length>0&&
                data.data.filter(x=>x.code<204).map(m=>{
                    arrs.push({text:m.value,value:m.code})
                })
                setDevTypeFilter(arrs);
            }
        })

        http.get("/v1/district", {}).then(data => {
            if (data.code === 0) {
                dispatch({ type: 'district', district: data.data });
                let arrs=[];
                data.data.map(m=>{
                    arrs.push({text:m.name,value:m.name})
                })
                setDisFilter(arrs);
            }
        })
    }, [])

    const columns = [
        {
            title: '所属区域',
            dataIndex: 'district_name',
            align: 'center',
            filters:disFilter,
            onFilter: (value, record) => record.district_name.indexOf(value) ===0,
        },
        {
            title: '测点名称',
            dataIndex: 'name',
            align: 'center',
            render: (value, record) => (
                <div>
                    <div>
                        {
                            record.name
                        }
                    </div>
                    <div style={{ fontSize: 10, color: '#666' }}>
                        {
                            'ID:' + ((record.node * 1 < 10) ? '0' + record.node : record.node)
                        }
                    </div>
                </div>
            ),
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) =>
            (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={nodeSerch}
                        placeholder='设备信息'
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => confirm()}
                        style={{ marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button size="small" style={{ width: 90 }} onClick={() => clearFilters()}>
                            重置
                        </Button>
                        <Button
                            type="primary"
                            onClick={() => confirm()}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            查找
                        </Button>

                    </Space>
                </div>
            ),
            onFilterDropdownVisibleChange: visible => {
                if (visible) {
                  setTimeout(() => nodeSerch.current.focus({cursor: 'all'}), 100);
                }
              },
            filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,

            onFilter: (value, record) =>
                record['node']? 
                (record['node'].toString().toLowerCase().includes(value.toLowerCase()) ||
                record['name'].toString().toLowerCase().includes(value.toLowerCase()))
                : '',
        },
        {
            title: '主机名称',
            dataIndex: 'm_name',
            align: 'center',
            render: (value, record) => (
                <div>
                    <div>
                        {
                            record.m_name
                        }
                    </div>
                    <div style={{ fontSize: 10, color: '#666' }}>
                        {
                            record.sn
                        }
                    </div>
                </div>
            ),
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) =>
            (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={devSerch}
                        placeholder='主机名称'
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => confirm()}
                        style={{ marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button size="small" style={{ width: 90 }} onClick={() => clearFilters()}>
                            重置
                        </Button>
                        <Button
                            type="primary"
                            onClick={() => confirm()}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            查找
                        </Button>

                    </Space>
                </div>
            ),
            onFilterDropdownVisibleChange: visible => {
                if (visible) {
                  setTimeout(() => devSerch.current.focus({cursor: 'all'}), 100);
                }
              },
            filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,

            onFilter: (value, record) =>
                record['m_name']||record['sn']
                    ? record['m_name'].toString().toLowerCase().includes(value.toLowerCase())||record['sn'].toString().toLowerCase().includes(value.toLowerCase())
                    : '',
        },
        {
            title:'追溯平台编号',
            dataIndex:'upSn',
            align:'center',
        },
        {
            title: '设备类型',
            dataIndex: 'type',
            align: 'center',
            sorter: {
                compare:(a, b) => a.type - b.type,
                multiple: 5,
            },
            render: (text, record) => (
                <div>
                    {
                        state.devicetype.filter(x => { return x.code === record.type }).length > 0 ? state.devicetype.filter(x => { return x.code === record.type })[0].value : '无'
                    }
                </div>
            ),
            filters:devTypeFilter,
            onFilter: (value, record) => record.type ===value,
        },
        {
            title: '操作',
            width: 80,
            dataIndex: 'operation',
            key: 'id',
            align: 'center',
            render: (text, record) => (
                <div>
                    <Button size='small' style={{ marginLeft: '10px',borderRadius:10 }} type="primary" onClick={() => {
                        setShowFixModel(true)
                        dispatchDevice({ type: 'update', device: record })
                    }}>
                        修改
                    </Button>
                </div>
            ),
        },
    ]

    const onfinish = (values) => {
        http.put("/v1/device/" + actDevice.id, {
            ...actDevice,
           upSn:values.upSn
        }).then((data) => {
            http.get("/v1/device", {}).then(data => {
                if (data.code === 0) {
                    let temp = data.data.filter(x=>x.node!==0).sort((a,b)=>a.sn-b.sn)
                    setTableData(temp);
                    dispatch({ type: 'device', device: temp });
                }
            })
        })
    }
    return (
        <div style={{ padding: 10 }}>
            <Table bordered pagination={{ hideOnSinglePage: true }} rowKey={record => record.id} columns={columns} size='small' dataSource={tableData} 
                rowClassName={record=>{if(state.super>1){
                    return record.status===1?'socDevice':record.hide===1?'hideDevice':''
                }}} 
            />
            <Modal maskClosable={false} title={
                    <div>修改上传设备</div>
                }  visible={showFixModel}
                destroyOnClose style={{cursor: 'move'}}
                cancelText='取消'
                width={600}
                okText='确定'
                onOk={() => {
                    form.submit();
                    setShowFixModel(false)
                }}
                onCancel={() => {
                    setShowFixModel(false)
                }}>
                <DeviceContext.Provider value={{ actDevice, dispatchDevice, form, onfinish }}>
                    <DeviceFix />
                </DeviceContext.Provider>
            </Modal>
        </div>
    )
}

export default UpDevManage