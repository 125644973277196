import React, { useState, useContext, useEffect } from 'react'
import { Avatar, DatePicker, Select, Divider, Button } from 'antd'
import { StateContext, TimeFormatSeconds } from '../../../state';
import http from '../../../../../utils/server'
import { DownloadOutlined } from '@ant-design/icons';
import "./index.less"

const { Option } = Select;

function BackUP() {
    const { state, dispatch } = useContext(StateContext)
    const [backupfile, setBackUPFile] = useState([]);
    const [showlist, setShowlist] = useState([]);

    useEffect(() => {
        // http.get("/v1/district", {}).then(data => {
        //     if (data.code === 0) {
        //         dispatch({ type: 'district', district: data.data })
        //     }
        // })
        http.get("/v1/users", {}).then(data => {
            if (data.code === 0) {
                dispatch({ type: 'systemuser', systemuser: data.data.sort((a,b)=>a.name.localeCompare(b.name,'zh')) })
            }
        })
        http.get("/v1/getbackupfile", {}).then(data => {
            if (data.code === 0) {
                var list = new Array();
                data.data && data.data.map(item => {
                    if (list.filter(x => { return x.date === item.date }).length === 0) {
                        var s = new Array();
                        s.push(item.district);
                        var a = new Object();
                        a['date'] = item.date;
                        a['districts'] = s;
                        list.push(a);
                    } else {
                        let s = list.filter(x => { return x.date === item.date })[0];
                        s.districts.push(item.district);
                    }
                })
                setShowlist(list);
                setBackUPFile(data.data);
            }
        })
    }, []);

    return (
        <>
            <div style={{ padding: 10 }}>
                {
                    showlist.map((item,index) => (
                        <div key={index}>
                            <Divider orientation="left" plain>
                                日期:{item.date}
                            </Divider>
                            <div style={{ display: 'flex', alignContent: 'flex-start', flexFlow: 'row wrap', }}>
                                {item.districts.map(d => (
                                    <div style={{ flex: '0 0 20%', padding: 10 }} key={d}>
                                        <div className={"backfile"}>
                                            <div>
                                                <div>
                                                    用户：
                                                    {
                                                        state.systemuser.list.filter(x => { return x.id === d }).length > 0 &&  state.systemuser.list.filter(x => { return x.id === d })[0].name
                                                    }
                                                </div>
                                                {/* <div>备份日期：{item.date}</div> */}
                                            </div>
                                            <div style={{ cursor: 'pointer' }}>
                                                <a href={"./backupfile/" + item.date + "." + d + ".zip"} download={ state.systemuser.list.filter(x => { return x.id === d }).length > 0 &&  state.systemuser.list.filter(x => { return x.id === d })[0].name}>
                                                    <DownloadOutlined style={{ fontSize: 48 }} />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))
                }

            </div>
        </>

    )
}

export default BackUP