import React, { createContext, useReducer, useCallback } from 'react';

export const StateDeviceContext = createContext({})


export const TimeFormatSeconds = function (time) {
    var d = time ? new Date(time) : new Date();
    var year = d.getFullYear();
    var month = d.getMonth() + 1;
    var day = d.getDate();
    var hours = d.getHours();
    var min = d.getMinutes();
    var seconds = d.getSeconds();

    if (month < 10) month = '0' + month;
    if (day < 10) day = '0' + day;
    if (hours < 0) hours = '0' + hours;
    if (min < 10) min = '0' + min;
    if (seconds < 10) seconds = '0' + seconds;

    return (year + '-' + month + '-' + day + ' ' + hours + ':' + min + ':' + seconds);
}

export const OPERATIONTYPE = ['增', '改', '删']

export const StateDevice = props => {
    const reducer = (state, action) => {
        switch (action.type) {
            case 'devicestate':
                return {
                    ...state,
                    devicestate: action.devicestate
                }
            default:
                return state
        }
    }

    const [state_device, dispatch_device] = useReducer(reducer, {
        devicestate: [],
    })
    return (
        <StateDeviceContext.Provider value={{ state_device, dispatch_device }}>
            {props.children}
        </StateDeviceContext.Provider>
    )
}