import React, { useState, useContext, useEffect} from 'react'
import { StateContext } from '../../../state';
import { Row, Col, Button, Badge, Modal, Tooltip } from 'antd'
import './device.less'
import {  SignalTower, BoltOne, BatteryWorking ,BatteryFull,BatteryEmpty} from '@icon-park/react'
import tempOnline from '../../../../../../public/assets/tempOnline.png'
import tempUnline from '../../../../../../public/assets/tempUnline.png'
import humiOnline from '../../../../../../public/assets/humiOnline.png'
import humiUnline from '../../../../../../public/assets/humiUnline.png'
import signalOnline from '../../../../../../public/assets/signalOnline.png'
import signalUnline from '../../../../../../public/assets/signalUnline.png'
import signalTimeOut from '../../../../../../public/assets/timeout.png'
import signalTimeWarn from '../../../../../../public/assets/timeWarn.png'
import DeviceSet from './set209-902'
import { Mode } from '../../../../../App';
import Draggable from 'react-draggable';
import DeviceHistory from './deviceHistory'
import DeviceMap from './deviceMap'

function formatDateTimes(inputTime) {
    var date = new Date(inputTime);
    var y = date.getFullYear();
    var m = date.getMonth() + 1;
    m = m < 10 ? ('0' + m) : m;
    var d = date.getDate();
    d = d < 10 ? ('0' + d) : d;
    var h = date.getHours();
    h = h < 10 ? ('0' + h) : h;
    var minute = date.getMinutes();
    var second = date.getSeconds();
    minute = minute < 10 ? ('0' + minute) : minute;
    second = second < 10 ? ('0' + second) : second;
    return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + second;
};
function Device902Item(props) {
    const { state, dispatch } = useContext(StateContext)
    const [showHis, setShowHis] = useState(false)
    const [showSet, setShowSet] = useState(false)
    const [showLocation, setShowLocation] = useState(false)
    //历史数据
    const [startTime, setStartTime] = useState('')//历史数据开始时间
    const [startDTime, setStartDTime] = useState('')//当天00:00:00
    const [endTime, setEndTime] = useState('')
    //轨迹回放
    const [startMapTime, setStartMapTime] = useState('');//轨迹数据的开始时间
    const [endMapTime, setEndMapTime] = useState('');//轨迹数据的结束时间

    const [modalBounds,setModalBounds] = useState({left: 0, top: 0, bottom: 0, right: 0 });//对话框移动坐标
    const [modalMoveDisabled, setModalMoveDisabled] = useState(true);//对话框拖动功能关闭
    const draggleRef = React.createRef();//移动对话框
    const [showLastTime, setShowLastTime] = useState(false);
    let timerout =0;
    // 拖动方法
    const onStart = (event, uiData) => {
        const { clientWidth, clientHeight } = window.document.documentElement;
        const targetRect = draggleRef.current?.getBoundingClientRect();
        if (!targetRect) return;
        setModalBounds({
            left: -targetRect.left + uiData.x,
            right: clientWidth - (targetRect.right - uiData.x),
            top: -targetRect.top + uiData.y,
            bottom: clientHeight - (targetRect.bottom - uiData.y),
        });
    };
    function dateStart() {
        var now_date = new Date(); now_date.setHours(0); now_date.setMinutes(0); now_date.setSeconds(0);
        return now_date;
    }
    const changeSetState = (num)=>{
        if(num>0 && state.main.type > 101){
            clearTimeout(timerout)
            timerout=setTimeout(() => {
                dispatch({ type: 'setState', setState: 0 });
                dispatch({ type: 'super', super: 0 });
            }, 1000*60*10);
        }
    }
    useEffect(()=>{
        let now_date = dateStart();
        setStartDTime(now_date);
        let type = state.main.type;
        let showLastTime = false;
        if (window.CefSharp !== undefined) {
            window.CefSharp.BindObjectAsync("bound");
            let myConf =window.bound.getConfig();
            console.log('myConf',myConf);
            if(type<102){
                if (myConf.showTimeAdmin){
                    showLastTime = true
                }
            }
            if(type===103){
                if (myConf.showTimeManger){
                    showLastTime = true
                }
            }
            if(type===104){
                if (myConf.showTimeUser){
                    showLastTime = true
                }
            }
        }else{
            if(type<102){
                showLastTime = true
            }
        }
        setShowLastTime(showLastTime)
    },[])

    return (
        <>
        {/* 历史数据 */}
            <Modal maskClosable={false} title={
                <div
                    style={{
                        width: '100%',
                        cursor: 'move',
                    }}
                    onMouseOver={() => {
                        setModalMoveDisabled(false);
                    }}
                    onMouseOut={() => {
                        setModalMoveDisabled(true);
                    }}
                >
                    历史数据
                </div>
                } width={'85vw'} 
                centered visible={showHis} destroyOnClose={true} 
                modalRender={modal => (
                    <Draggable
                        disabled={modalMoveDisabled}
                        bounds={modalBounds}
                        onStart={onStart}
                    >
                      <div ref={draggleRef}>{modal}</div>
                    </Draggable>
                )}
                footer={null}
                onCancel={() => {
                    setStartTime('');
                    setEndTime('');
                    setShowHis(false)
                    props.callback(false)
                }}
            >
                <DeviceHistory  item={props.item} start={startTime} end ={endTime} showMore = {false}></DeviceHistory>
 
            </Modal>
            <Modal maskClosable={false} title={
                <div
                    style={{
                        width: '100%',
                        cursor: 'move',
                        display:'flex',
                        justifyContent:'space-between',
                        alignItems:'center',
                        paddingRight:20
                    }}
                    onMouseOver={() => {
                        setModalMoveDisabled(false);
                    }}
                    onMouseOut={() => {
                        setModalMoveDisabled(true);
                    }}
                >
                    <div>
                        参数设置
                    </div>
                    <div>
                        <span>{Mode!=='soc'?null:' 用户: ' + state.district.filter(item => item.id === props.item.District)[0].user_name}</span>
                    </div>
                    <div>
                        <span>{' 区域: ' + state.district.filter(item => item.id === props.item.District)[0].name}</span>
                    </div>
                </div>
            }
            modalRender={modal => (
                <Draggable
                disabled={modalMoveDisabled}
                bounds={modalBounds}
                onStart={onStart}
                >
                    <div ref={draggleRef}>{modal}</div>
                </Draggable>
            )}
                width={1000} centered visible={showSet} footer={null} destroyOnClose={true}
                onCancel={() => {
                    setShowSet(false)
                    props.callback(false)
                }}
            >
                 
                <DeviceSet item={props.item} cloud={props.cloud} changeSetState = {changeSetState} />
                 
            </Modal>
            <Modal maskClosable={false} title={
                    <div
                    style={{
                        width: '100%',
                        cursor: 'move',
                    }}
                    onMouseOver={() => {
                        setModalMoveDisabled(false);
                    }}
                    onMouseOut={() => {
                        setModalMoveDisabled(true);
                    }}
                    >
                    轨迹回放
                    </div>
                } width={'100%'} centered visible={showLocation} destroyOnClose footer={null}
                modalRender={modal => (
                    <Draggable
                    disabled={modalMoveDisabled}
                    bounds={modalBounds}
                    onStart={onStart}
                    >
                        <div ref={draggleRef}>{modal}</div>
                    </Draggable>
                )}
                onCancel={() => {
                    setShowLocation(false);
                    props.callback(false);
                    setStartMapTime('');
                    setEndMapTime('');
                }}
            >
                <DeviceMap item ={props.item} start={startMapTime} end ={endMapTime}></DeviceMap>
            </Modal>
            {
            !props.item.ExSensor?'':
            !props.item.ExSensor.param?'':
            props.show === 1 ?
                //列表布局 
                <div className={'rowItem'} style={{ borderLeft: props.cloud ? '1px solid rgb(11, 112, 143)' : '' }} 
                onDoubleClick={() =>{
                    startTime===''&& setStartTime(formatDateTimes(startDTime.getTime()));
                    endTime===''&& setEndTime(formatDateTimes(new Date().getTime()));
                    setShowHis(true);
                }}>
                    <Row align='middle' >
                        {/* 测点名称 */}
                        <Tooltip title={props.item.MName}>
                        <Col xxl={5} xl={4} style={{display:'flex',alignItems:'center'}}>
                            {/* 设备到期状态 */}
                            <div style={{ width: 30 }}>
                            {
                                props.item.ExprieStatus === 0 ? ''
                                :props.item.ExprieStatus === 3 ? <img style={{ width: 20, height: 20 }}src={ signalTimeOut } alt="" />
                                :<img style={{ width: 20, height: 20 }}src={ signalTimeWarn } alt="" />
                            }
                            </div>
                            {
                                props.item.Node===0?
                                <div>
                                    <div style={{ fontSize: 14, fontWeight: 'bold', color: '#000' }}>{props.item.MName}</div>
                                    <div style={{  fontSize: 12, color: '#000' }}>{'设备状态'}</div>
                                </div>:
                                <div >
                                    <div style={{ fontSize: 14, fontWeight: 'bold', color: '#000' }}>{props.item.Name}</div>
                                    <div style={{ display: 'flex', fontSize: 12, color: '#000' }}>
                                        <div style={{width:100 ,overflow:'hidden',textOverflow:'ellipsis',whiteSpace:'nowrap' }}>
                                            {props.item.MName}
                                        </div>
                                        <div>{" ID:" +  props.item.Sn+'-'+props.item.Node}</div>
                                    </div>
                                </div>
                            }
                        </Col>
                        </Tooltip>
                        {/* 设备状态 是否在线 */}
                        <Col xxl={1} xl={2}>
                            <span className={'devicestate'}><Badge  text={props.item.Online ?props.item.Node===0?"在线":props.item.ExSensor.param[0].value === 102.3 ? '未连接' : props.item.ExSensor.param[0].value === 102.2 ? '正在连接' : props.item.ExSensor.param[0].enable !==1 ?'停用':"在线" : "离线"} status={props.item.Online ? "success" : "default"} /></span>
                        </Col>
                        {/* 设备温湿度值  开关门 电源状态 */}
                        <Col xxl={8} xl={8}>
                        {
                            // 开关门
                            (props.item.Node===0&&props.item.Sensor.PowerAlarm===0&&props.item.Sensor.DoorAlarm===1)?
                            <div style={{ display: 'flex',fontSize:16,fontWeight:'bold',justifyContent:'center' }}>
                                {
                                    props.item.Online ?
                                    props.item.Status.Door===1  ?
                                    <span style={{color:'red'}}> 开 门 </span>:
                                    <span> 关 门 </span>:
                                    '--------------'
                                }
                            </div>:
                            //电源状态
                            (props.item.Node===0&&props.item.Sensor.PowerAlarm===1&&props.item.Sensor.DoorAlarm===0)?
                            <div style={{ display: 'flex',fontSize:16,fontWeight:'bold' ,justifyContent:'center'}}>
                                {
                                    props.item.Online ?
                                    props.item.Status.PowerStatus===1 ?
                                    <span style={{color:'red'}}> 断 电 </span>:
                                    <span > 电 源 正 常 </span>:
                                    '--------------'
                                }
                            </div>:
                            // 开关门 电源状态同时
                            (props.item.Node===0&&props.item.Sensor.PowerAlarm===1&&props.item.Sensor.DoorAlarm===1)?
                            <div style={{fontSize:16,fontWeight:'bold'}}>
                                <div style={{fontWeight:'normal',fontSize:10,display:'flex',flexDirection:'row',justifyContent:'space-evenly'}}>
                                    <span style={{display:'inline-block'}}>电源状态</span>
                                    <span style={{display:'inline-block'}}>门状态</span>
                                </div>
                                {
                                    props.item.Online ?
                                    <div style={{ display: 'flex',justifyContent:'space-evenly'}}>
                                        <div >
                                            {
                                                props.item.Status.PowerStatus===1 ?
                                                <span style={{color:'red'}}> 断 电 </span>:
                                                <span > 正 常 </span>
                                            }
                                        </div>
                                        <div >
                                            {
                                                props.item.Status.Door===1  ?
                                                <span style={{color:'red'}}> 开 门 </span>:
                                                <span > 关 门  </span>
                                            }
                                        </div>
                                    </div>:
                                    <div style={{ display: 'flex',justifyContent:'space-evenly'}}>
                                        <div ><span style={{color:'#ccc'}}>--------------</span></div>
                                        <div><span style={{color:'#ccc'}}>--------------</span></div>
                                    </div>
                                }
                            </div>:
                            // 正常温湿度数据
                            <div style={{ display: 'flex',justifyContent:'center'}}>
                                <div style={{width:60}}>
                                    <div>
                                        <span>上限:{props.item.Online&&props.item.ExSensor.param[0].high}</span>
                                    </div>
                                    <div>
                                        <span>下限:{props.item.Online&&props.item.ExSensor.param[0].low}</span>
                                    </div>
                                </div>
                                <div style={{ display: 'flex',alignItems:'center' ,width:100}}>
                                    <img width={32} src={props.item.Online ? tempOnline:tempUnline} />

                                    <div style={{ fontSize: 18, color: props.item.Online?props.item.ExSensor.param[0].alarm===0?'black':props.item.ExSensor.param[0].alarm===1? 'red' :'orange':'black'}}>
                                        {
                                            props.item.Online?props.item.ExSensor.param[0].value === 102.3 || props.item.ExSensor.param[0].value === 102.2||props.item.ExSensor.param[0].enable!==1 ? '--' :
                                            props.item.ExSensor.param[0].value + props.item.ExSensor.param[0].unit:'--'
                                        }
                                    </div>
                                </div>
                                {
                                    props.item.Humi === 1 ?
                                        <div style={{display: 'flex'}}>
                                            <div style={{ display: 'flex',width:120,alignItems:'center'}}>
                                                <img width={32} src={props.item.Online ? humiOnline : humiUnline} />
                                                {/* <div style={{ fontSize: 18, color:!props.item.Humi?'black':erjinzhi(props.item.Sensor.AlarmStatus).slice(1, 2) === '1' ? 'red' : 'black' }}> */}
                                                <div style={{ fontSize: 18, color:props.item.Sensor.HumiAlarm? 'red' :props.item.Sensor.HumiWarning?'orange':'black'}}>
                                                    {props.item.Sensor.HumiValue === 102.3 || props.item.Sensor.HumiValue === 102.2 ||!props.item.Sensor.HumiSwitch? '--' : props.item.Sensor.HumiValue + "%RH"}
                                                </div>
                                            </div> 
                                            <div style={{width:60}}>
                                                <div>
                                                    <span>上限:{props.item.Sensor.HumiAlarmHigh}</span>
                                                </div>
                                                <div>
                                                    <span>下限:{props.item.Sensor.HumiAlarmLow}</span>
                                                </div>
                                            </div>
                                        </div>: null
                                }
                            </div>
                        }
                        </Col>
                        {/* 信号电源电量 */}
                        <Col xxl={3} xl={3}>
                            <div style={{display:'flex',flexDirection:'row'}}>
                                {/* 定位状态 */}
                                <div className='gomap' style={{ width: 70, display: 'flex', alignItems: 'center', }} onClick={() => {
                                    startMapTime===''&& setStartMapTime(formatDateTimes(startDTime.getTime()));
                                    endMapTime===''&& setEndMapTime(formatDateTimes(new Date().getTime()));
                                    setShowLocation(true);
                                }}>
                                    <SignalTower theme="two-tone" size="24" fill={props.item.Online ?props.item.Status.BaseLac!==0? ['#48c7e9', '#ffffff'] : ['#ccc', '#ddd']: ['#ccc', '#ddd']} />
                                    <span style={{ fontSize: 14}}>LBS</span>
                                </div> 
                                {/* 信号值 */}
                                <div style={{ width: 60, display: 'flex', alignItems: 'center', }}>
                                    <img style={{ width: 24, height: 24, marginBottom: 5 }} src={props.item.Online && props.item.Status.Csq > 0 ? signalOnline : signalUnline} alt="" />
                                    <span style={{ fontSize: 14 }}>{props.item.Online && props.item.Status.Csq > 0 ? props.item.Status.Csq : ' '}</span>
                                </div>
                                {/* 电量状态 值 */}
                                <div style={{ width: 60, display: 'flex', alignItems: 'center', }}>
                                    {
                                        props.item.Status.PowerStatus === 0 ?
                                            <BoltOne theme="two-tone" size="24" fill={props.item.Online ? ['#48c7e9', '#ffffff'] : ['#ccc', '#ddd']} /> :
                                            <BatteryWorking theme="two-tone" size="24" fill={props.item.Online ?  ['#48c7e9', '#ffffff'] : ['#ccc', '#ddd']} />
                                    }
                                    <span style={{ fontSize: 14 }}>{props.item.Online ? props.item.Status.BatPower + '%' : ''}</span>
                                </div>
                            </div>
                        </Col>
                        {/* 时间 */}
                        {
                            // state.main.type<102&&
                            // <Col xxl={4} xl={3} style={{color: '#666', fontSize: 12,textAlign:'center'}}>
                            //     <div>更新时间：</div>
                            //     <div>{props.item.Status.LastMsgTime}</div>
                            // </Col>
                        }
                        {/* 轨迹回放按钮 */}
                        <Col xxl={3} xl={4}>
                            <div style={{display:'flex',justifyContent:'space-between'}}>
                                <Button style={{ marginRight: 5,borderRadius:10 }} 
                                onClick={() => {
                                    (props.item.ExprieStatus !== 3||state.main.type===101) && setShowSet(true)
                                }} 
                                disabled={props.item.ExprieStatus === 3 && state.main.type!==101}>
                                   参数设置
                                </Button>
                                <Button style={{ marginRight: 5,borderRadius:10 }} disabled={props.item.ExprieStatus === 3&&state.main.type!==101 } onClick={() => {
                                startTime===''&& setStartTime(formatDateTimes(startDTime.getTime()));
                                endTime===''&& setEndTime(formatDateTimes(new Date().getTime()));
                                setShowHis(true);
                                props.callback(true);
                                }}>历史数据</Button>  
                            </div>
                        </Col>
                    </Row>
                </div> 
            :props.show === 0 ?
                //图表布局
                <div style={{  marginBottom: 8, alignItems: 'center' }} >
                    <div style={{ border: '1px solid #ccc', borderLeft: props.cloud ? '1px solid rgb(11, 112, 143)' : '1px solid #ccc',position:'relative'}} className='chartLay'>
                        <div>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                <Tooltip title={props.item.Node===0?props.item.MName:props.item.Name}>
                                <div style={{ fontSize: '11px', fontWeight: 'bold', flex: 1,overflow:'hidden',textOverflow:'ellipsis',whiteSpace:'nowrap' }}>
                                    <span className={'devicestate'}>
                                        {
                                            props.item.ExprieStatus === 0 ? <Badge status={props.item.Online ? "success" : "default"} />
                                            :props.item.ExprieStatus === 3 ? <img style={{ width: 20, height: 20 }}src={ signalTimeOut } alt="" />
                                            :<img style={{ width: 20, height: 20 }}src={ signalTimeWarn } alt="" />
                                            // <AlertOutlined  style={{color:'brown',fontSize:16}}/>
                                        }
                                    </span>
                                    <span className='fontContent' style={{fontSize:12}} >{props.item.Node===0?props.item.MName:props.item.Name}</span>
                                    {/* <span>{props.item.Sensor.Hide===2&&' 空库'}</span> */}
                                </div>
                                </Tooltip>
                                {
                                    props.item.Online&&props.item.Node>0&&props.item.ExSensor&&(props.item.ExSensor.param[0].enable!==1|| props.item.ExSensor.param[0].value === 102.3|| props.item.ExSensor.param[0].value === 102.2||props.item.Sensor.Hide===2||props.item.Sensor.Hide===3)&&
                                    <div style={{width:55,fontSize:10,border:'1px solid #C3CDDC',color:'#666',borderRadius:'0px 5px 0px 15px',padding:'2px 0px',textAlign:'center'}}>
                                        {
                                            props.item.ExSensor.param[0].enable!==1 ? '停用': props.item.ExSensor.param[0].value === 102.3 ? '未连接' : props.item.ExSensor.param[0].value === 102.2 ? '正在连接' :props.item.Sensor.Hide===2||props.item.Sensor.Hide===3?'空库' :''
                                        }
                                    </div>
                                }
                               

                            </div>
                            {/* 状态 测点ID 信号状态 */}
                            <div style={{ display: 'flex',justifyContent:'space-between'}}>
                                <div style={{flex: 1 }}> 
                                    <span className='fontSecond' style={{ display: 'inline-block',paddingLeft:5 }}>{props.item.Node===0?'设备状态':"ID:" +props.item.Sn+'-'+ props.item.Node}</span>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-evenly', padding: 0,width:70,}}>
                                    <Tooltip title={props.item.Location === 1 && props.item.Online ? 'LBS定位' : ''}>
                                        <SignalTower className='gomap' theme="two-tone" size="14" fill={props.item.Location === 1 && props.item.Online ? props.item.Status.BaseLac!==0?['#21B97E', '#ffffff'] : ['#ccc', '#ddd']: ['#ccc', '#ddd']} onClick={() => {
                                            startMapTime===''&& setStartMapTime(formatDateTimes(startDTime.getTime()));
                                            endMapTime===''&& setEndMapTime(formatDateTimes(new Date().getTime()));
                                            setShowLocation(true);
                                            }} />
                                    </Tooltip> 
                                    <Tooltip title={props.item.Status.Csq}>
                                        <img style={{ width: 12, height: 10,marginTop:5 }} src={!props.item.Online ? signalUnline:props.item.Node===0?signalOnline:props.item.ExSensor.param[0].value<100 && props.item.ExSensor.param[0].enable===1  ? signalOnline : signalUnline} alt="" />
                                    </Tooltip>
                                    {
                                        
                                        props.item.Node===0?
                                        <Tooltip title={props.item.Status.BatPower + '%' }>
                                            {
                                                props.item.Status.PowerStatus === 0 ?
                                                <BoltOne theme="two-tone" size="14" fill={props.item.Online? ['#21B97E', '#ffffff'] : ['#ccc', '#ddd']} /> :
                                                props.item.Status.BatPower>80?
                                                <BatteryFull theme="outline" size="20" fill={props.item.Online ? '#21B97E': '#ccc'}/>
                                                :props.item.Status.BatPower>30?
                                                <BatteryWorking theme="two-tone" size="20" fill={props.item.Online ? ['#21B97E', '#ffffff'] : ['#ccc', '#ddd']} />
                                                :props.item.Status.BatPower>10?
                                                <BatteryWorking theme="two-tone" size="20" fill={props.item.Online ? ['#f4ea2a', '#ffffff'] : ['#ccc', '#ddd']} />
                                                :
                                                <BatteryEmpty theme="outline" size="20" fill={props.item.Online ? '#d81e06': '#ccc'}/>
                                            }
                                        </Tooltip>
                                        :
                                        <Tooltip title={ props.item.Status.BatPower + '%'}>
                                            {
                                                props.item.Status.PowerStatus === 0 ?
                                                <BoltOne theme="two-tone" size="20" fill={props.item.Online && props.item.ExSensor.param[0].enable===1 && props.item.ExSensor.param[0].value < 100 ? ['#21B97E', '#ffffff'] : ['#ccc', '#ddd']} /> :
                                                props.item.Online &&props.item.ExSensor.param[0].enable===1?
                                                props.item.Status.BatPower>80?
                                                <BatteryFull theme="outline" size="20" fill={'#21B97E'}/>
                                                :props.item.Status.BatPower>30?
                                                <BatteryWorking theme="two-tone" size="20" fill={['#21B97E', '#ffffff']} />
                                                :props.item.Status.BatPower>10?
                                                <BatteryWorking theme="two-tone" size="20" fill={['#f4ea2a', '#ffffff']} />
                                                :<BatteryEmpty theme="outline" size="20" fill={'#d81e06'}/>
                                                :<BatteryEmpty theme="outline" size="20" fill='#ccc'/>
                                                    
                                            }
                                        </Tooltip>
                                    }
                                    
                                </div>
                            </div>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row', borderTop: '1px dashed #ccc', marginTop: '5px', alignItems: 'center' }}>
                            {
                                showLastTime?
                                <div style={{ flex: 1, color: '#666', fontSize: 10, paddingLeft: 10 }}>
                                    {"更新时间：" + props.item.Status.LastMsgTime}
                                </div>
                                :
                                <div style={{ height:10 }}>
                                   
                                </div>
                            }                
                            

                        </div>
                        {/* 温湿度数据 */}
                        <div>
                            <div style={{ display: 'flex', marginTop: '10px',  justifyContent: 'space-around' }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <svg t="1722841637517" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1542" width="20" height="28">
                                    <path d="M516.266667 53.546667a156.245333 156.245333 0 0 0-147.925334 156.032l-0.042666 310.314666-2.986667 2.304a257.92 257.92 0 1 0 417.194667 202.88l-0.256-10.922666a257.536 257.536 0 0 0-98.389334-192l-3.029333-2.261334V209.578667A156.245333 156.245333 0 0 0 524.586667 53.333333L516.266667 53.546667zM524.586667 117.333333c50.944 0 92.245333 41.301333 92.245333 92.245334v326.826666a32 32 0 0 0 14.421333 26.666667 193.92 193.92 0 1 1-213.333333 0 32 32 0 0 0 14.378667-26.709333V209.578667c0-50.944 41.344-92.245333 92.288-92.245334z" fill={props.item.Online ?"#6099F5":'#C8C8C8'} p-id="1543"></path>
                                    <path d="M375.637333 270.976m32 0l85.333334 0q32 0 32 32l0 0q0 32-32 32l-85.333334 0q-32 0-32-32l0 0q0-32 32-32Z" fill={props.item.Online ?"#6099F5":'#C8C8C8'} p-id="1544"></path>
                                    <path d="M375.637333 398.976m32 0l85.333334 0q32 0 32 32l0 0q0 32-32 32l-85.333334 0q-32 0-32-32l0 0q0-32 32-32Z" fill={props.item.Online ?"#6099F5":'#C8C8C8'} p-id="1545"></path>
                                    <path d="M444.074667 641.536a34.133333 34.133333 0 0 1 17.365333 41.685333l-1.664 3.968a72.533333 72.533333 0 0 0 27.904 93.994667l5.504 2.986667a34.133333 34.133333 0 1 1-29.909333 61.354666 140.8 140.8 0 0 1-64.853334-188.288 34.133333 34.133333 0 0 1 45.653334-15.701333z" fill={props.item.Online ?"#6099F5":'#C8C8C8'} p-id="1546"></path></svg>

                                    {/* <img width={32} src={props.item.Online ? tempOnline : tempUnline} /> */}
                                    <div className='fontContent' style={{color: props.item.Online?props.item.ExSensor.param[0].alarm===0?'black':props.item.ExSensor.param[0].alarm===1? 'red' :'orange':'black' ,padding:'6px 0px 0px 0px'}}>
                                        {
                                            props.item.Online?
                                            props.item.ExSensor.param[0].value === 102.3 || props.item.ExSensor.param[0].value === 102.2 || props.item.ExSensor.param[0].enable!==1 ? '--' 
                                            : props.item.ExSensor.param[0].value 
                                            :'--'
                                        }
                                        <span style={{fontSize:12}}>{props.item.ExSensor.param[0].enable===1&&(props.item.ExSensor.param[0].value !== 102.3 || props.item.ExSensor.param[0].value !== 102.2 )&&' '+ props.item.ExSensor.param[0].unit}</span>
                                    </div>
                                </div>
                                {
                                    props.item.Humi === 1 ?
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <svg t="1722842134014" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4477" width="18" height="28">
                                                <path d="M334.915327 733.720777c-5.27719 0-10.313903-3.091408-12.539594-8.262174-1.705851-3.970428-2.332115-6.901177-3.385097-11.779278-0.49221-2.291183-1.1328-5.27719-2.104941-9.380648-1.719154-7.322779 2.824325-14.678304 10.153244-16.410762 7.275707-1.65264 14.671141 2.825348 16.416901 10.154267 1.01205 4.337795 1.692548 7.482415 2.212388 9.907651 0.905626 4.210905 1.146103 5.250584 1.785669 6.715959 2.971681 6.928806-0.226151 14.950504-7.141654 17.935488C338.552158 733.347271 336.727603 733.720777 334.915327 733.720777z" fill={props.item.Online ?"#6099F5":'#C8C8C8'} p-id="4478"></path>
                                                <path d="M491.102552 860.978113c-0.613984 0-1.225921-0.025583-1.852184-0.106424-60.150962-8.207939-113.319905-42.37511-145.886625-93.704149-4.037966-6.370081-2.145873-14.791891 4.224208-18.84316 6.342452-4.02364 14.777565-2.158153 18.828834 4.238534 28.237111 44.534286 74.356498 74.142627 126.512369 81.257675 7.476275 1.013073 12.698207 7.889691 11.686157 15.35164C503.682056 856.022241 497.817488 860.978113 491.102552 860.978113z" fill={props.item.Online ?"#6099F5":'#C8C8C8'} p-id="4479"></path>
                                                <path d="M512.30341 957.589474c-161.557536 0-293.000194-131.442658-293.000194-293.007357 0-104.245226 151.577231-443.271686 190.30016-520.492418 25.851784-51.54291 61.84351-79.925331 101.367689-79.925331 22.799262 0 66.293869 10.367115 101.527324 79.805604 40.827871 80.486102 192.805214 415.235143 192.805214 520.612145C805.303604 826.145793 673.860946 957.589474 512.30341 957.589474zM510.971066 118.744451c-17.283642 0-36.938284 18.614963-52.569286 49.810453-47.425126 94.544283-184.517457 411.94419-184.517457 496.027214 0 131.475404 106.949824 238.426251 238.419088 238.426251 131.470287 0 238.419088-106.950847 238.419088-238.426251 0-82.790588-132.655276-388.984269-186.889481-495.919766C547.736411 136.933719 528.467555 118.744451 510.971066 118.744451z" fill={props.item.Online ?"#6099F5":'#C8C8C8'} p-id="4480"></path></svg>
                                            {/* <img width={32} src={props.item.Online ? humiOnline : humiUnline} /> */}
                                            <div className='fontContent' style={{  color:props.item.Sensor.HumiAlarm? 'red' :props.item.Sensor.HumiWarning?'orange' :'black',padding:'6px 0px 0px 0px'}}>
                                                {props.item.Sensor.HumiValue === 102.3 || props.item.Sensor.HumiValue === 102.2 || !props.item.Sensor.HumiSwitch ?  '--' : props.item.Sensor.HumiValue}
                                                <span style={{fontSize:12}}>{props.item.Sensor.HumiSwitch && props.item.Sensor.HumiValue<100&&' %RH'}</span>
                                            </div>
                                        </div> : null
                                }
                            </div>
                            
                            {/* {
                                state.main.type>101&&<div style={{height:5}}></div>
                            } */}
                            
                        </div>
                        <div style={{padding:5,width:'100%',overflow:'hidden',textOverflow:'ellipsis',whiteSpace:'nowrap',textAlign:'center'}} className='showName' >
                            <div style={{height:20}}></div>
                            <div className='fontSecond' style={{color:'#999'}}>
                            {
                                props.item.MName
                            }
                            </div>
                        </div>
                        <div className='showSetting'>
                            {
                            <div style={{display: 'flex',justifyContent: 'space-around',marginTop:5,fontSize:8}} className='fontSecond'>
                                <div>
                                    <span >
                                        上下限:
                                        {
                                            props.item.Online?`${props.item.ExSensor.param[0].high}~${props.item.ExSensor.param[0].low}`
                                                :''
                                        }
                                    </span>
                                </div>
                                {
                                        props.item.Humi === 1 &&
                                        <div>
                                        {
                                            
                                            <span>
                                                上下限:{props.item.Sensor.HumiAlarmHigh}~{props.item.Sensor.HumiAlarmLow}
                                            </span>
                                        }
                                        
                                    </div>
                                }
                                
                            </div>                                
                            }
                            <div style={{ display: 'flex', justifyContent: 'space-between',padding:'0px 10px',marginTop:5}}>
                                <Tooltip title={props.item.MName}>
                                    <Button  className='fontSecond' size='small' style={{borderRadius:5,padding:'1px 3px',color:'#999'}}
                                        onClick={() => { 
                                            startTime===''&& setStartTime(formatDateTimes(startDTime.getTime()));
                                            endTime===''&& setEndTime(formatDateTimes(new Date().getTime()));
                                            setShowHis(true);
                                        }}
                                        disabled={props.item.ExprieStatus === 3&& state.main.type>101 }
                                    >
                                        <div style={{width:65,overflow:'hidden',textOverflow:'ellipsis',whiteSpace:'nowrap'}}>
                                            {props.item.MName}
                                        </div>
                                        
                                    </Button>
                                </Tooltip>  
                                
                                <div>
                                    <Tooltip title={'设置参数'}>
                                        <Button  size='small'  className='fontSecond' style={{ borderRadius:5,padding:'1px 8px',color:'#999'}}
                                        onClick={() => {
                                            (props.item.ExprieStatus !== 3||state.main.type<102) &&setShowSet(true);
                                        }} 
                                        disabled={(props.item.ExprieStatus === 3 && state.main.type>101)||props.item.Node===0}>
                                            参数
                                        </Button>
                                    </Tooltip>
                                    <Tooltip title={'历史数据'}>
                                        <Button  size='small' className='fontSecond' style={{ borderRadius:5,padding:'1px 8px',color:'#999',marginLeft:5}}
                                        onClick={() => {
                                            let now_date = dateStart();
                                            startTime===''&& setStartTime(formatDateTimes(now_date.getTime()));
                                            endTime===''&& setEndTime(formatDateTimes(new Date().getTime()));
                                            setShowHis(true)
                                            props.callback(true)
                                        }} disabled={props.item.ExprieStatus === 3&& state.main.type>101 }
                                        >数据</Button>
                                    </Tooltip>
                                </div>
                                    
                            </div>
                        </div>
                        
                        
                    </div>
                </div>
            ://精简布局
                <div style={{ border:props.item.ExprieStatus>2 ?'2px solid red': props.item.ExprieStatus>0? '2px solid orange':'', backgroundColor:props.item.Sensor.Hide>1 && '#ccc' }} className='chartLay1'
                onDoubleClick={()=>{
                    (props.item.ExprieStatus !== 3||state.main.type<102) &&setShowSet(true);
                }}>
                    <Tooltip title={
                    <div>
                        <div>{props.item.Node===0?props.item.MName:props.item.Name}</div>
                        <div>{props.item.Sn + ' - ' + props.item.Sensor.Id}</div>
                        <div>更新时间:{props.item.Status.LastMsgTime}</div>
                        <div>温度上下限: {props.item.ExSensor.param&&(props.item.ExSensor.param[0].high+'~'+props.item.ExSensor.param[0].low+props.item.ExSensor.param[0].unit)}</div>
                    </div>
                   }>
                    <div>
                        <div style={{ padding:'2px 8px',backgroundColor:props.item.Online&&'#1890ff',borderRadius:5}}
                             onClick={() => { 
                                startTime===''&& setStartTime(formatDateTimes(startDTime.getTime()));
                                endTime===''&& setEndTime(formatDateTimes(new Date().getTime()));
                                setShowHis(true);
                            }}
                            disabled={props.item.ExprieStatus === 3&& state.main.type>101 }
                        >
                            <div style={{ fontSize: '14px',color:'#000', flex: 1,overflow:'hidden',textOverflow:'ellipsis',whiteSpace:'nowrap' }}>
                                <span>{props.item.Node===0?props.item.MName:props.item.Name}</span>
                            </div>
                        </div>
                    </div>
                    {
                        props.item.Node===0?
                        (props.item.Sensor.PowerAlarm===0&&props.item.Sensor.DoorAlarm===1)?
                        <div style={{ display: 'flex',fontWeight:'bold',fontSize:12,height:70,justifyContent:'center',alignItems:'center'}}>
                            {
                                props.item.Online ? 
                                props.item.Status.Door===1 ?
                                <span style={{color:'red',}}> 开 门 </span>:
                                <span > 关 门 </span>:
                                <span style={{color:'#ccc'}}>--------------</span>
                            }
                        </div>:
                        (props.item.Sensor.PowerAlarm===1&&props.item.Sensor.DoorAlarm===0)?
                        <div style={{ display: 'flex',fontWeight:'bold',fontSize:12,height:70,justifyContent:'center',alignItems:'center'}}>
                            {
                                props.item.Online ?
                                props.item.Status.PowerStatus===1 ?
                                <span style={{color:'red'}}> 断 电 </span>:
                                <span > 电 源 正 常 </span>:
                                <span style={{color:'#ccc'}}>--------------</span>
                            }
                        </div>:
                        <div style={{ display: 'flex',flexDirection:'column',fontSize:12,fontWeight:'bold',height:70,justifyContent:'center'}}>
                            <div style={{fontSize:10,fontWeight:'normal',display:'flex',justifyContent:'space-around'}}>
                                <span>电源状态</span>
                                <span>门状态</span>
                            </div>
                            {
                                props.item.Online ?
                                <div style={{ display: 'flex',justifyContent:'space-around'}}>
                                    <div >
                                        {
                                                props.item.Status.PowerStatus===1 ?
                                            <span style={{color:'red'}}> 断 电 </span>:
                                            <span >  正 常 </span>
                                        }
                                    </div>
                                    <div >
                                        {
                                            props.item.Status.Door===1  ?
                                            <span style={{color:'red'}}> 开 门  </span>:
                                            <span > 关 门 </span>
                                        }
                                    </div>
                                </div>:
                                <div style={{ display: 'flex',justifyContent:'space-around'}}>
                                    <div style={{width:80}}><span style={{color:'#ccc'}}>--------------</span></div>
                                    <div style={{width:80}}><span style={{color:'#ccc'}}>--------------</span></div>
                                </div>
                            }
                        </div>:
                        <div>
                            <div style={{ display: 'flex', marginTop: '10px',  justifyContent: 'space-around' }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <img width={24} src={props.item.Online ? tempOnline : tempUnline} />
                                    <div style={{ fontSize: 20,fontWeight:'bold',color:!props.item.ExSensor.param?'black': props.item.ExSensor.param[0].alarm===0?'black':props.item.ExSensor.param[0].alarm===1? 'red' :'orange' }}>
                                        {!props.item.ExSensor.param?'--':props.item.ExSensor.param[0].value === 102.3 || props.item.ExSensor.param[0].value === 102.2 || props.item.ExSensor.param[0].enable!==1 ? '--' : props.item.ExSensor.param[0].value }
                                        <span style={{fontWeight:'normal',fontSize:14}}>{props.item.ExSensor.param&&props.item.ExSensor.param[0].unit}</span>
                                    </div>
                                </div>
                                {
                                    props.item.Humi === 1 ?
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <div style={{ fontSize: 14, color:props.item.Sensor.HumiAlarm? 'red' :props.item.Sensor.HumiWarning?'orange' :'black'}}>
                                                {props.item.Sensor.HumiValue === 102.3 || props.item.Sensor.HumiValue === 102.2 || !props.item.Sensor.HumiSwitch ?  '--' : props.item.Sensor.HumiValue + "%RH"}
                                            </div>
                                        </div> : null
                                }
                            </div>
                        </div>
                    }
                    </Tooltip>
                </div>
            }
        </>
    )
}

export default Device902Item