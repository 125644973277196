import {useContext,useEffect} from 'react'
import Sider from './sider'
import Header from './header'
import Content from './content'
import { ConfigProvider } from 'antd'
import{StateContext} from './state'
import zhCN from 'antd/lib/locale/zh_CN';
import { Mode } from '../../App'
import moment from 'moment'
import 'moment/locale/zh-cn'
moment.locale('zh-cn')
function Mnanger(props) {
    const { state, dispatch } = useContext(StateContext)
    useEffect(()=>{
        const getResolution = () => {
            const width = window.screen.width;
            const height = window.screen.height;
            dispatch({ type: 'resolution', resolution: {width,height}})
          };
          getResolution();
          window.addEventListener('resize', getResolution);
    },[]
    )
    if (Mode === 'soc') {
        return (
            <ConfigProvider locale={zhCN}>
                <div style={{ display: 'flex', height: '100vh' ,backgroundColor:'#000C17',width:'100%',overflow:'hidden'}}>
                    <Sider />
                    <div style={{ height: '100vh', display: 'flex', flexDirection: 'column',backgroundColor:"#E4E5E6",flexGrow:1 }}>
                        <Header />
                        <Content >{props.children}</Content>
                    </div>
                </div>
            </ConfigProvider>
        )
    } else {
        return (
            <ConfigProvider locale={zhCN}>
                <div style={{ display: 'flex', height: '100vh',flexDirection: 'column',flexGrow:1,backgroundColor:'#E4E5E6'}}>
                    <Header local />
                    <Content >{props.children}</Content>
                </div>
            </ConfigProvider>
        )
    }
}
export default Mnanger;