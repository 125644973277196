import React, { useEffect, useState, useReducer  } from 'react'
import './index.less'
import {Button,Input,Modal,Table,Radio, Select, Tabs,Space} from 'antd'
import { SearchOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
function isPay(d) {
    let nowDate_month = new Date().getMonth()+1;
    let nowDate_year = new Date().getFullYear();
    let simExp = new Date(d);
    let simExp_year = simExp.getFullYear();
    let simExp_month = simExp.getMonth()+1;
    let dis = false;
    if (simExp_year > nowDate_year){
        dis = false;
    }else if(simExp_year===nowDate_year){
        if(nowDate_month-simExp_month>2){
            dis = true;
        }else{
            dis = false;
        }
    }else if(nowDate_year - simExp_year===1){
        if(simExp_month<10||nowDate_month>2){
            dis = true;
        }
        if(simExp_month===12){
            dis =nowDate_year<3? false:true;
        }
        if(simExp_month===11){
            dis =nowDate_year<2? false:true;
        }
        
    }else{
        dis = true
    }
    return dis;
}
function PayManage(){
    const nodeSerch = React.createRef();
    const columns =[
        {
            title:'设备编号',
            dataIndex:'sn',
            sorter: (a, b) => a.sn-b.sn,
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) =>
            (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={nodeSerch}
                        placeholder='设备信息'
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => confirm()}
                        style={{ marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button size="small" style={{ width: 90 }} onClick={() => clearFilters()}>
                            重置
                        </Button>
                        <Button
                            type="primary"
                            onClick={() => confirm()}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            查找
                        </Button>

                    </Space>
                </div>
            ),
            onFilterDropdownVisibleChange: visible => {
                if (visible) {
                  setTimeout(() => nodeSerch.current.focus({cursor: 'all'}), 100);
                }
              },
            filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,

            onFilter: (value, record) =>
                record['sn']? 
                (record['sn'].toString().toLowerCase().includes(value.toLowerCase()) ||
                record['sn'].toString().toLowerCase().includes(value.toLowerCase()))
                : '',
        },
        {
            title:'设备类型',
            dataIndex:'devType',
        },
        {
            title:'设备到期时间',
            dataIndex:'devExpire'
        },
        {
            title:'sim卡号',
            dataIndex:'sim',
        },
        {
            title:'sim卡到期时间',
            dataIndex:'simExpire'
        },
        {
            title:'续费',
            dataIndex:'action',
            render: (text, record) => (
                <Button size='small' type='primary'  style={{borderRadius:10 }} disabled={isPay(record.simExpire)}
                    onClick={()=>{
                        setShowModal(true)
                        setSelectedDev([record]);
                        setSelVal(record.sn);
                    }}
                >
                    续费
                </Button>
            )
        }
    ]
    const tableData =[
        {
            key: '1',
            sn: 9300000001,
            devType:'管理主机',
            devExpire:'2022-10-01',
            sim: 1440102030401,
            simExpire:'2022-06-30'
        },
        {
            key: '2',
            sn: 9300000002,
            devType:'车载',
            devExpire:'2022-11-30',
            sim: 1440102030402,
            simExpire:'2022-07-30'
        },
        {
            key: '3',
            sn: 9300000003,
            devType:'保温箱',
            devExpire:'2022-12-31',
            sim: 1440102030403,
            simExpire:'2021-09-30'
        },
        {
            key: '4',
            sn: 9300000004,
            devType:'一拖二',
            devExpire:'2022-10-31',
            sim: 1440102030404,
            simExpire:'2022-09-30'
        },
        {
            key: '5',
            sn: 9300000005,
            devType:'冰柜',
            devExpire:'2022-10-01',
            sim: 1440102030405,
            simExpire:'2022-09-30'
        },
    ]
    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRow(selectedRows)
        },
        getCheckboxProps: (record) => {
            let dis = isPay(record.simExpire);
            return({
                disabled:dis ,
                sn: record.sn,
            })
        },
    };
    const { Option } = Select;
    const [showModal,setShowModal] = useState(false);
    const [itemValue,setItemValue] = useState(1);
    const [modeValue,setModeValue] = useState(1);
    const [selectedDev,setSelectedDev] = useState([]);
    const [selectedRow,setSelectedRow] = useState([]);
    const [selVal,setSelVal] = useState('');
    return (
        <div className='flexCon'>
            <Tabs
                defaultActiveKey="1"
                // onChange={onChange}
            >
                <Tabs.TabPane tab="到期续费" key="1">
                    <div>
                        <div className='flexCol'>
                            <div className='flexRow' style={{justifyContent:'space-between'}}>
                                <Input addonBefore='设备：'  style={{width:300}}/>
                                <Input addonBefore='sim卡号：' style={{width:300}} />
                                <Button type='primary'>查询</Button>
                            </div>
                            <div  className='flexRow' style={{justifyContent:'space-between',paddingTop:0}}>
                                <div></div>
                                <Button type='primary' onClick={()=>{
                                    setShowModal(true);
                                    setSelectedDev(selectedRow);
                                    let showVal = '';         
                                    selectedRow.map(item=>{
                                        showVal+=item.sn+','
                                    })
                                    showVal = showVal.slice(0,-1);
                                    setSelVal(showVal);
                                }}>批量续费</Button>
                            </div>
                        </div>
            
                        <div className='flexCon'>
                            <Table columns={columns} dataSource={tableData} 
                            rowSelection={{
                            ...rowSelection,
                            }}></Table>
                        </div>
                        <Modal visible={showModal}
                            width={1000}
                            title="续费详情"
                            footer={null}
                            onCancel={()=>setShowModal(false)}
                        >
                            <div>
                                <div className='flexRow'>
                                    <div>续费项目：</div>
                                    <div>
                                        <Radio.Group onChange={e=>{
                                                let tempVal = e.target.value
                                                setItemValue(tempVal)
                                                let showVal = '';
                                                if (tempVal===2){
                                                    selectedDev.map(item=>{
                                                        showVal+=item.sim+','
                                                    })
                                                    
                                                }else{
                                                    selectedDev.map(item=>{
                                                        showVal+=item.sn+','
                                                    })
                                                }
                                                showVal = showVal.slice(0,-1);
                                                setSelVal(showVal)
                                            }} value = {itemValue}
                                        >
                                            <Radio value={1}>服务费</Radio>
                                            <Radio value={2}>卡费</Radio>
                                            <Radio value={3}>综合</Radio>
                                        </Radio.Group>
                                    </div>
                                </div>
                                <div className='flexRow'>
                                    <div>{itemValue===2?'SIM卡号':'设备标号'}：</div>
                                    <div><Input disabled style={{width:800}} value={selVal}/></div>
                                </div>
                                <div className='flexRow'>
                                    <div>续费时长：</div>
                                    <div>
                                        <Select defaultValue={1} style={{ width: 800 }} >
                                            <Option value={1}>1个月</Option>
                                            <Option value={2}>2个月</Option>
                                            <Option value={3}>3个月</Option>
                                            <Option value={4}>6个月</Option>
                                            <Option value={5}>12个月</Option>
                                            <Option value={6}>13个月</Option>
                                            <Option value={7}>24个月</Option>
                                        </Select>
                                    </div>
                                </div>
                                <div className='flexRow'>
                                    <div>支付金额：</div>
                                    <div><Input disabled style={{ width: 800 }} /></div>
                                </div>
                                <div className='flexRow'>
                                    <div>续费方式：</div>
                                    <div>
                                        <Radio.Group onChange={e=>{
                                                setModeValue(e.target.value)
                                            }} value = {modeValue} 
                                        >
                                            <Radio value={1}>微信</Radio>
                                            <Radio value={2}>支付宝</Radio>
                                            <Radio value={3}>线下</Radio>
                                        </Radio.Group>
                                    </div>
                                </div>
                            </div>
                        </Modal>
                    </div>
                </Tabs.TabPane>
                <Tabs.TabPane tab="订单详情" key="2" >

                </Tabs.TabPane>
            </Tabs>
            
        </div>
    )
}
export default  PayManage 
